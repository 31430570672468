import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../hooks/useIsMobile';
import Button from '../ui/Button.tsx';
import Divider from '../ui/Divider.tsx';
import Header from '../ui/Header.tsx';
import Link from '../ui/Link.tsx';
import Modal from '../ui/Modal.tsx';
import Text from '../ui/Text.tsx';
import MailIcon from '../../public/icons/raboIcons/mail.svg';
import PhoneIcon from '../../public/icons/raboIcons/phone.svg';
import Abla from '../../public/images/abla.jpg';
import Max from '../../public/images/max.jpg';
import Ralph from '../../public/images/ralph.jpg';
import SendMessage from './SendMessage';

function ContactDetails() {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <>
      <div className="flex-col hidden bg-white rounded lg:flex">
        <div className="px-8 py-5 border-b border-gray-medium">
          <Header
            langKey="text_65ae40be14c97a0052707082"
            element="h5"
          />
        </div>

        {/* csc in sidebar */}
        <div className="flex flex-col gap-4 px-8 py-4">
          <div className="flex flex-row">
            <img src={String(Abla)} className="rounded-full w-14 h-14 border-2 border-white -mr-2" alt="Abla" />
            <img src={String(Max)} className="rounded-full w-14 h-14 border-2 border-white -mr-2" alt="Max" />
            <img src={String(Ralph)} className="rounded-full w-14 h-14 border-2 border-white" alt="Ralph" />
          </div>
          <Text
            langKey="text_66fab24338a5580055ea4763"
            size="sm"
          />
          <Divider />
        </div>

        <div className="flex flex-col gap-4 px-8 py-4">
          <div className="flex gap-6">
            <PhoneIcon className="mt-2" />
            <div className="flex flex-col flex-1">
              <Link href={t('text_65d143184fd1b3009489cc1e')}>
                <Text
                  langKey="text_65ae40be14c97a0052707083"
                  size="sm"
                  weight="semibold"
                  color="secondary"
                  className="underline hover:text-[inherit]"
                />
              </Link>
              <Text
                langKey="text_65ae40be14c97a0052707084"
                size="sm"
              />
            </div>
          </div>

          <div className="flex gap-6">
            <MailIcon className="mt-2" />
            <div className="flex flex-col flex-1 text-sm">
              <Button
                className="justify-start mr-auto p-0 font-semibold underline hover:text-primary"
                label={t('text_65ae40be14c97a0052707085')}
                name="send_message_open_modal"
                color="secondary"
                type="submit"
                variant="link"
                onClick={() => setIsOpen(!isOpen)}
              />
              <Text
                langKey="text_65ae40be14c97a0052707086"
                size="sm"
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        noFocus={isMobile}
        isOpen={isOpen}
        showCloseButton
        position="bottom"
        setIsOpen={setIsOpen}
        className="max-w-2xl"
      >
        <SendMessage setIsOpen={setIsOpen} />
      </Modal>
    </>
  );
}

export default ContactDetails;
