import { useEffect, useState } from 'react';

const TABLET_WIDTH = 640;

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < TABLET_WIDTH);

  useEffect(() => {
    const handleChange = () => {
      setIsMobile(window.innerWidth <= TABLET_WIDTH);
    };

    handleChange();

    window.addEventListener('resize', handleChange);

    return () => {
      window.removeEventListener('resize', handleChange);
    };
  }, []);

  return isMobile;
}

export default useIsMobile;
