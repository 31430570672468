import React from 'react';
import { adornmentAlignment } from '../../utils/constants.ts';
import Cell from '../ui/Cell.tsx';
import Divider from '../ui/Divider.tsx';
import Header from '../ui/Header.tsx';
import Text from '../ui/Text.tsx';
import Checkmark from '../../public/icons/checkmark.svg';

function ELigibilityTerms({ question, answers }) {
  return (
    <div className="flex flex-col gap-2 w-full text-left text-sm">
      <Header
        langKey={question}
        element="h5"
      />
      <Divider
        className="flex"
        color="dark"
      />
      <ul className="flex flex-col gap-2">
        {answers.map((key) => (
          <li key={key}>
            <Cell
              beginAdornment={<Checkmark className="mt-2 fill-success w-3" />}
              beginAdornmentPosition={adornmentAlignment.top}
            >
              <Text langKey={key} size="sm" />
            </Cell>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ELigibilityTerms;
