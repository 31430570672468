import React, { useEffect, useState } from 'react';
import type { FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputOptionView, { type InputOptionViewProps, type OptionDetail } from './InputOptionView';

type ComboboxProps<
  T extends OptionDetail,
  K extends FieldValues,
  U extends Path<K>,
> = InputOptionViewProps<T, K, U>;

function Combobox<T extends OptionDetail, K extends FieldValues, U extends Path<K>>({
  options,
  ...props
}: ComboboxProps<T, K, U>) {
  const [items, setItems] = useState<T[]>([]);
  const { t } = useTranslation();

  const emptyItem = {
    id: 'empty',
    title: t('error.combobox.empty'),
  } as T;

  useEffect(() => setItems(options.length ? options : [emptyItem]), [options]);

  return (
    <InputOptionView
      {...props}
      shouldOpen={options?.length > 0}
      options={items}
      readOnly={false}
    />
  );
}

export default Combobox;
