import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { cn } from '../../utils/helpers.ts';
import Dropdown from '../../components/ui/Dropdown.tsx';

function FaqPicker({ selectedOption, setSelectedOption, options }) {
  const { t } = useTranslation();
  const { control } = useForm();

  const component = 'faq';
  const inputName = 'faq_option';

  return (
    <>
      <Dropdown
        options={options}
        displayValue={(value) => value?.title}
        control={control}
        placeholder={t(`${component}.input.placeholder`)}
        name={inputName}
        className="lg:hidden"
        handleChange={setSelectedOption}
      />

      <div className="hidden w-full justify-between gap-2 p-4 lg:flex overflow-scroll">
        {options.map((option) => {
          const { title } = option;
          const isSelected = title === selectedOption?.title;

          return (
            <button
              onClick={() => setSelectedOption(!isSelected && option)}
              type="button"
              className={cn(
                'flex justify-center items-center gap-4 pointer-events-auto min-w-max py-2 px-4',
                'text-sm font-semibold leading-6',
                'rounded-3xl',
                isSelected ? 'text-white bg-blue-background' : 'bg-gray-100 text-blue-background',
              )}
              name={`${component}_${title}`}
              id={`${component}_${title}`}
              aria-label={`${component}_${title}`}
            >
              {title}
            </button>
          );
        })}
      </div>
    </>
  );
}

export default FaqPicker;
