import React from 'react';
import { joinValues } from '../../utils/helpers';
import Accordion from '../../components/container/Accordion';
import Cell from '../../components/ui/Cell';
import Text from '../../components/ui/Text';
import Checkmark from '../../public/icons/checkmark.svg';

function ProductRequirements() {
  const items = [
    'text_65a4842dab1a0c007b58f4b8',
    'text_65a4842dab1a0c007b58f4b9',
  ];

  return (
    <div
      className={joinValues({
        base: 'flex justify-center w-full px-6 pb-6 -mt-28',
        lg: 'lg:-mt-[90px] lg:pb-20',
      })}
    >
      <div className="relative bg-white rounded max-w-component shadow-rabobank-md">
        <Accordion
          className="px-6 pt-10 pb-6 lg:px-24"
          langKey="text_65a4842dab1a0c007b58f485"
          size="h4"
          variant="vertical"
        >
          <ul className="flex flex-col gap-4 text-sm sm:px-32 lg:px-14">
            {items.map((key) => (
              <li key={key}>
                <Cell
                  beginAdornment={<Checkmark className="mt-[9px] fill-success" />}
                  beginAdornmentPosition="top"
                >
                  <Text langKey={key} size="sm" />
                </Cell>
              </li>
            ))}
          </ul>
        </Accordion>
      </div>
    </div>
  );
}
export default ProductRequirements;
