import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { getQueryParams } from '../../utils/helpers.ts';
import Accordion from '../../components/container/Accordion.tsx';
import Divider from '../../components/ui/Divider.tsx';
import Header from '../../components/ui/Header.tsx';
import Text from '../../components/ui/Text.tsx';

function FaqItems({ selectedOption, options }) {
  const [shouldGoToActive, setShouldGoToActive] = useState(true);

  const queryParams = getQueryParams();
  const activeFaq = queryParams.active;

  const animationContainer = {
    hide: {
      opacity: 0,
      transition: {
        when: 'beforeChildren',
        duration: 0.2,
      },
    },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: selectedOption ? 0.05 : 0,
        duration: 0.2,
      },
    },
    exit: { opacity: 0 },
  };

  const animationItem = {
    hide: { opacity: 0 },
    show: { opacity: 1 },
  };

  useEffect(() => setShouldGoToActive(false), []);

  return (
    <div className="flex flex-col gap-10 lg:gap-20">
      <AnimatePresence mode="wait">
        <motion.div
          variants={animationContainer}
          initial="hide"
          animate="show"
          exit="exit"
          key={selectedOption ? selectedOption.id : 'multiple'}
          className="flex flex-col gap-10 lg:gap-20"
        >
          {(selectedOption ? [selectedOption] : options).map((option) => {
            const { title, items } = option;

            return (
              <div key={option.id} className="flex flex-col">
                <Header className="pb-4 lg:px-4" element="h4">
                  {title}
                </Header>

                {items.map((item) => (
                  <motion.div variants={animationItem} key={item}>
                    <Accordion
                      langKey={`faq.${item}.question`}
                      size="md"
                      chevronType="thin"
                      variant="border"
                      padding="lg:px-4"
                      color="black"
                      weight="semibold"
                      isActive={item === activeFaq && shouldGoToActive}
                    >
                      <Text
                        langKey={`faq.${item}.answer`}
                        className="pb-5 lg:px-4"
                      />
                    </Accordion>
                  </motion.div>
                ))}

                <Divider />
              </div>
            );
          })}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default FaqItems;
