import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history;

    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }

    const container = document.getElementById('app_container');
    container?.scroll({ top: 0 });
  }, [pathname]);

  return children;
}

export default ScrollToTop;
