import React from 'react';
import { joinValues, transition } from '../../utils/helpers';
import ComponentLayout from '../../components/layout/ComponentLayout';
import Header from '../../components/ui/Header';
import Text from '../../components/ui/Text';
import MoneyToPhoneIcon from '../../public/icons/money_to_phone.svg';

type BlogItemProps = {
  header: string;
  text: string;
};

function BlogItem({ header, text }: BlogItemProps) {
  return (
    <div className="flex flex-col gap-2">
      <Header langKey={header} element="h3" />
      <Text langKey={text} size="sm" />
    </div>
  );
}

function BlogUpdates() {
  const component = 'blog_updates';

  const items = [
    {
      header: 'text_65a4842dab1a0c007b58f487',
      text: 'text_65a4842dab1a0c007b58f488',
    },
    {
      header: 'text_65a4842dab1a0c007b58f489',
      text: 'text_65a4842dab1a0c007b58f48a',
    },
  ];

  return (
    <ComponentLayout
      transition={transition}
      layoutId={component}
      className={joinValues({
        base: 'flex-col items-center justify-center w-full py-10 gap-5',
        lg: 'lg:flex-row-reverse lg:gap-14 lg:pb-20',
      })}
    >
      <MoneyToPhoneIcon className="w-[326px] lg:w-[480px]" />
      <div className="flex flex-col gap-4 max-w-lg lg:gap-6">
        <Text
          langKey="text_65a4842dab1a0c007b58f486"
          className="underline underline-offset-4"
          color="primary"
          size="xs"
          weight="semibold"
        />
        <div className="flex flex-col gap-6">
          {items.map(({ header, text }) => (
            <BlogItem key={`${header}-${text}`} header={header} text={text} />
          ))}
        </div>
      </div>
    </ComponentLayout>
  );
}

export default BlogUpdates;
