export const unknownError = 'generic';

export type RejectionError =
  | 'GENERIC'
  | 'TIMEOUT'
  | 'DLS_ELIGIBILE_RED_SCORE'
  | 'RED_SCORE'
  | 'TRANSACTION_STATUS'
  | 'NO_CONNECTION_ID'
  | 'GET_TRANSACTIONS'
  | 'CONSENT_DECLINED'
  | 'CREATE_CONNECTION'
  | 'NEW_TO_THE_BANK'
  | 'TRANSACTIONS_NOT_RECENT'
  | 'INVOICE_NOT_SUITABLE'
  | 'INSUFFICIENT_TRANSACTIONS'
  | 'CRC_CHECK_FAILED'
  | 'SALTEDGE_CONNECTION_ERROR'
  | 'SUREPAY_NO_MATCH'
  | 'RISK_EVALUATION_FAILED'
  | 'MAX_LOAN_EXCEEDED';

/**
 * TODO: the backend should return an error code so we can use the code to map it
 * to the corresponding error message
 *
 * Some of the errors are "disabled" by commenting it out so it'll fall back to a
 * generic error. In the future when we have the translation text, the errors can
 * be enabled again.
 */
export const responseErrors = {
  invoice: {
    INVALID_SBI: 'SBI_CODE_INVALID',
    ERR_3002: 'issue_date_newer_than_today',
    ERR_3003: 'max_due_date',
    // ERR_3005: 'duplicate_invoices',
    // ERR_3006: 'details_already_exist',
    ERR_3007: 'debtor_and_requester_kvk_match',
  },
  company: {
    ALPHANUMERIC: 'alphanumeric',
  },
  transaction: {
    // ERR_5000: 'multiple_tx_error_in_transaction',
    // ERR_5001: 'no_transactions_uploaded',
    // ERR_5002: 'transactions_do_not_sum_up',
    ERR_5003: 'transactions_not_recent',
    ERR_5004: 'not_enough_transactions',
    ERR_5005: 'too_many_days_in_file',
    // ERR_5006: 'no_transactions_in_upload',
    // ERR_5007: 'no_balance_in_upload',
    // ERR_5008: 'file_empty',
    // ERR_5009: 'unknown_file_extension',
    // ERR_5010: 'file_has_no_balance',
    // ERR_5011: 'unrequested_file',
    // ERR_5012: 'missing_transaction_data',
    // ERR_5013: 'missing_company_data',
    // ERR_5014: 'no_balance_after_merge',
    // ERR_5015: 'no_transactions_after_merge',
    // ERR_5016: 'invalid_bank',
    // ERR_5017: 'invalid_file_extension_for_bank',
    // ERR_5018: 'multiple_file_extension',
    // ERR_5019: 'number_of_files_mismatch',
    // ERR_5020: 'balance_and_transaction_files_upload_mandatory',
    // ERR_5021: 'balance_and_transaction_files_account_number_mismatch',
    // ERR_5023: 'transaction_file_invalid_content',
    // ERR_5024: 'balance_file_invalid_content',
    // ERR_5025: 'transaction_file_name_invalid',
    // ERR_5026: 'balance_file_name_invalid',
    // ERR_5027: 'invalid_kvk',
    // ERR_5028: 'camt_file_not_uploaded',
    // ERR_5029: 'balance_and_transaction_must_be_from_same_bank',
    // ERR_5030: 'bank_mandatory',
    ERR_5031: 'multiple_accounts',
    // ERR_7000: 'transaction_upload_failed',
    // ERR_7001: 'transaction_state_not_found',
  },
};

export const uploadErrors = {
  GENERIC: 'upload',
  FILENAME_TOO_LONG: 'filename_too_long',
};

export const offerErrors: Record<string, RejectionError> = {
  ERR_6001: 'DLS_ELIGIBILE_RED_SCORE',
};

export const riskErrors: Record<string, RejectionError | RiskError> = {
  CRC_0001: 'CRC_CHECK_FAILED',
  RER_0003: 'INVOICE_NOT_SUITABLE',
  RER_0004: 'RECURRING_CUSTOMER',
  RER_0006: 'MAX_LOAN_EXCEEDED',
  RER_0007: 'RISK_EVALUATION_FAILED',
};

export const statusErrors: Record<number, string> = {
  400: 'bad_request',
  413: 'file.too_big',
  500: 'generic',
};

export type RiskError =
  | 'RECURRING_CUSTOMER';

export type CompanyError =
  | 'INVALID_KVK_NUMBER'
  | 'INVALID_ADDRESS';
