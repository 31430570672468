import React, { useRef } from 'react';
import { measureVideo } from '../../utils/analytics';
import { cn } from '../../utils/helpers';
import ComponentLayout from '../layout/ComponentLayout';
import Header from '../ui/Header';
import Text from '../ui/Text';
import VideoLandingpageThumbnail from '../../public/images/video_landingpage_thumbnail.jpg';
import VideoLandingpage from '../../public/videos/video_landingpage.mp4';

const videos = {
  landingpage: {
    header: 'text_65dc6258cc75da00e51e3db4',
    text: 'text_65dc6258cc75da00e51e3db5',
    src: VideoLandingpage,
    poster: VideoLandingpageThumbnail,
  },
  kortlopendeLeningLandingPage: {
    header: 'text_667170be881bda3dc1788db9',
    text: '',
    src: VideoLandingpage,
    poster: VideoLandingpageThumbnail,
  },
} as const;

type ExplanationVideoProps = {
  page: keyof typeof videos;
  className?: string;
};

function ExplanationVideo({ page, className }: ExplanationVideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleVideoEvent = (action: 'play' | 'ended') => {
    measureVideo({ value: action });
  };

  const videoEventHandlers = {
    onPlay: () => handleVideoEvent('play'),
    onEnded: () => handleVideoEvent('ended'),
  };

  return (
    <ComponentLayout className={cn('w-full justify-center', className)}>
      <div className="flex flex-col w-full md:max-w-component gap-4 pb-10 sm:gap-6 lg:pb-20">
        <Header
          langKey={videos[page].header}
          element="h3"
        />
        <Text className="px-24" langKey={videos[page].text} />
        <div className="relative w-full pb-[56%]">
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            className="absolute w-full h-full object-cover border border-gray-medium rounded"
            controls
            ref={videoRef}
            poster={String(videos[page].poster)}
            {...videoEventHandlers}
          >
            <source src={String(videos[page].src)} type="video/mp4" />
            <Text langKey="text_65c3711d3d601e00ce12d9a0" />
          </video>
        </div>
      </div>
    </ComponentLayout>
  );
}

export default ExplanationVideo;
