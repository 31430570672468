import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { buttonClick } from '../../utils/analytics';
import { cn, transitionDuration } from '../../utils/helpers.ts';
import CrossIcon from '../../public/icons/cross_thin.svg';
import WarningIcon from '../../public/icons/warning.svg';
import Text from './Text.tsx';

export const notificationType = {
  error: 'error',
  warning: 'warning',
};

const colorMapper = {
  [notificationType.error]: {
    bg: 'bg-red-light',
    border: 'border-error',
    fill: 'fill-error',
  },
  [notificationType.warning]: {
    bg: 'bg-yellow-light',
    border: 'border-warning',
    fill: 'fill-warning',
  },
};

function Notification({ titleKey, langKey, className, type, layout, shouldDelay, name }) {
  const [isVisible, setIsVisible] = useState(true);
  const { t } = useTranslation();

  const title = titleKey ?? 'warning.default';
  const color = colorMapper[type];

  const handleClose = () => {
    buttonClick(t('event.close', { notification: type }));
    setIsVisible(false);
  };

  const transition = {
    delay: shouldDelay ? transitionDuration : 0,
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={cn(className, color.bg, color.border, 'p-4 border border-l-8 rounded')}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition }}
          exit={{ opacity: 0 }}
          data-testid={name}
          key={type}
          layout={layout}
          transition={{ duration: 0.3 }}
        >
          <div className="flex items-start gap-3">
            <div className="flex-shrink-0">
              <WarningIcon className={cn(color.fill, 'w-8 mt-px text-black-400')} aria-hidden="true" />
            </div>
            <div className="flex flex-col gap-2">
              <Text
                name={`${name}_title`}
                langKey={title}
                weight="semibold"
                color="custom"
                className="text-blue-dark"
              />
              <Text
                name={`${name}_text`}
                langKey={langKey}
                size="sm"
                color="custom"
                className="text-blue-dark"
              />
            </div>
            {type !== notificationType.error && (
              <button
                className="ml-auto"
                type="button"
                aria-label="warning_close"
                name="warning_close"
                id="warning_close"
                onClick={handleClose}
              >
                <CrossIcon />
              </button>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Notification;

Notification.defaultProps = {
  titleKey: undefined,
  layout: '',
  className: '',
  shouldDelay: false,
  name: undefined,
};

Notification.propTypes = {
  type: PropTypes.oneOf(Object.values(notificationType)).isRequired,
  titleKey: PropTypes.string,
  layout: PropTypes.string,
  className: PropTypes.string,
  langKey: PropTypes.string.isRequired,
  shouldDelay: PropTypes.bool,
  name: PropTypes.string,
};
