import React from 'react';
import { useTranslation } from 'react-i18next';
import { cn, joinValues, transition } from '../../utils/helpers';
import InfoWithButton, { infoWithButtonTheme } from '../container/InfoWithButton';
import ComponentLayout from '../layout/ComponentLayout';
import Header from './Header';
import Text from './Text';

type ProductVariantDetails = {
  layout?: string;
  wrapper: string;
  className: string;
};

type ProductVariantKeys = keyof typeof variantOptions;

type ProductVariant = {
  [K in ProductVariantKeys]: ProductVariantDetails;
};

const variantOptions = {
  normal: {
    wrapper: joinValues({
      base: 'my-10',
      sm: 'sm:mx-10',
      md: 'md:max-w-xl',
      lg: 'lg:max-w-component lg:my-20',
    }),
    className: 'lg:px-28',
  },
  fullWidth: {
    wrapper: '',
    layout: 'py-10 w-full lg:py-20',
    className: 'sm:max-w-xs md:max-w-md',
  },
};

export const otherProductVariant: ProductVariant = variantOptions;

type Content = {
  header: string;
  text: string;
  button: string;
};

type OtherProductProps = {
  content: Content;
  name: string;
  variant: ProductVariantKeys;
  onClick: () => void;
};

function OtherProduct({ content, name, variant = 'normal', onClick }: OtherProductProps) {
  const { t } = useTranslation();
  const { header, text, button } = content;

  const component = 'other_product.factuur_financiering';
  const { layout, wrapper, className } = otherProductVariant[variant];

  return (
    <ComponentLayout
      transition={transition}
      layoutId={component}
      className={layout}
    >
      <InfoWithButton
        buttonLabel={t(button)}
        theme={infoWithButtonTheme.BLUE_WHITE}
        className={wrapper}
        name={name}
        onClick={onClick}
      >
        <div className={cn(className, 'flex flex-col gap-2 text-center lg:gap-6')}>
          <Header
            langKey={header}
            color="blue"
            element="h3"
          />
          <Text
            langKey={text}
            color="black"
          />
        </div>
      </InfoWithButton>
    </ComponentLayout>
  );
}

export default OtherProduct;
