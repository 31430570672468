import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toCurrency, toInt, transition } from '../../utils/helpers';
import { isValidAmount } from '../../utils/validation';
import ComponentLayout from '../../components/layout/ComponentLayout';
import Button from '../../components/ui/Button';
import Divider from '../../components/ui/Divider';
import Dropdown from '../../components/ui/Dropdown';
import Header from '../../components/ui/Header';
import { CurrencyInput } from '../../components/ui/Input';
import Text from '../../components/ui/Text';

const MIN_AMOUNT = Number(process.env.REACT_APP_MIN_LOAN_AMOUNT);
const MAX_AMOUNT = Number(process.env.REACT_APP_MAX_LOAN_AMOUNT);

const component = 'example_calculator.factuur_financiering';

export const inputNames = {
  amount: 'amount',
  period: 'period',
};

type CalculatorProps = {
  setUsedCalculator: React.Dispatch<React.SetStateAction<boolean>>;
};

function Calculator({ setUsedCalculator }: CalculatorProps) {
  const { t } = useTranslation();
  const { amount, period } = inputNames;

  const [totalAmount, setTotalAmount] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);

  const amountErrorMessage = t('error.amount', {
    minAmount: toCurrency(MIN_AMOUNT),
    maxAmount: toCurrency(MAX_AMOUNT),
  });

  const periodOptions = [
    {
      id: '0',
      title: t('text_65e5a7e782bac8008e232e99'),
      value: 1.2,
    },
    {
      id: '1',
      title: t('text_65e5a7e995a9c50065d67318'),
      value: 2.3,
    },
    {
      id: '2',
      title: t('text_65e5a7eb22d51500e7415940'),
      value: 3.5,
    },
  ];

  const {
    register,
    formState,
    control,
    watch,
  } = useForm({ mode: 'onBlur' });

  const {
    errors: formErrors,
    isValid,
  } = formState;

  const { amount: invoiceAmount, period: selectedPeriod } = watch();

  useEffect(() => {
    if (!invoiceAmount || !selectedPeriod) {
      return;
    }

    if (!isValid) {
      setTotalAmount(0);
      setTotalInterest(0);
      return;
    }

    const selectedInterestRate = selectedPeriod.value;
    setInterestRate(selectedInterestRate);

    const intInvoiceAmount = toInt(invoiceAmount);
    const interest = (+intInvoiceAmount / 100) * selectedInterestRate;

    const calculatedAmount = intInvoiceAmount - interest;
    const calculatedInterest = interest;

    setTotalAmount(calculatedAmount);
    setTotalInterest(calculatedInterest);
  }, [invoiceAmount, selectedPeriod, isValid]);

  const onButtonClick = () => {
    const appContainer = document.getElementById('app_container');
    appContainer?.scrollTo({ top: 0, behavior: 'smooth' });

    if (invoiceAmount && selectedPeriod) {
      setUsedCalculator(true);
    }
  };

  return (
    <ComponentLayout
      transition={transition}
      layoutId={component}
      className="flex-col py-14 gap-10 w-full"
    >
      <div className="flex flex-col gap-5 items-center text-center lg:gap-3">
        <Header
          langKey="text_65e5a7c2420d9b00be75e7ca"
          element="h2"
        />
        <Text
          langKey="text_65e5a7b292ec5800e3dcd5d3"
          size="sm"
          className="max-w-lg"
        />
      </div>
      <div className="flex flex-col items-center gap-4">
        <div className="relative p-6 w-full max-w-4xl rounded border shadow-rabobank-md">
          <div className="w-full flex flex-col gap-4 text-sm sm:flex-row">
            <form className="w-full flex flex-col gap-2 justify-center">
              <CurrencyInput
                placeholder={t('text_65e5a7cad37a7400590be98a')}
                label={t('text_65e5a7c710685e00d857eebc')}
                error={formErrors[amount]?.message?.toString()}
                allowDecimals={false}
                {...register(amount, {
                  required: t('error.required'),
                  validate: {
                    [amount]: (value) => (
                      isValidAmount(value, MIN_AMOUNT, MAX_AMOUNT) || amountErrorMessage
                    ),
                  },
                })}
              />
              <Dropdown
                options={periodOptions}
                displayValue={(value) => value?.title}
                control={control}
                placeholder={t('text_65e5a7d395a9c50065d67317')}
                error={formErrors[period]?.message?.toString()}
                name={period}
                label={t('text_65e5a7d0420d9b00be75e7cb')}
                size="md"
                rules={{
                  required: t('error.required'),
                }}
              />
            </form>
            <Divider className="sm:h-auto sm:border-r " />
            <div className="w-full flex flex-col justify-between">
              <div className="flex justify-between font-semibold mb-4">
                <Header
                  langKey="text_65e5a7d60ee1af0076437548"
                  className="font-semibold"
                  size="h5"
                  element="p"
                />
                <Header
                  className="font-semibold"
                  size="h5"
                  element="p"
                >
                  {toCurrency(totalAmount)}
                </Header>
              </div>
              <div className="flex justify-between font-semibold">
                <Text
                  size="sm"
                  langKey="period_interest"
                />
                <Text
                  size="sm"
                >
                  {`${interestRate} %`}
                </Text>
              </div>
              <div className="flex justify-between font-semibold">
                <Text
                  size="sm"
                  langKey="interest_cost"
                />
                <Text
                  size="sm"
                >
                  {toCurrency(totalInterest)}
                </Text>
              </div>
              <div className="flex justify-between font-semibold">
                <Text
                  size="sm"
                  langKey="text_65e5a7e2750f5400d78c71ed"
                />
                <Text
                  size="sm"
                >
                  € 0
                </Text>
              </div>
              <div className="flex justify-between font-semibold mt-4 mb-1">
                <Text
                  size="sm"
                  langKey="text_65e6d6425fcb4400d6a65bc6"
                />
              </div>
              <Button
                name={`${component}_button`}
                variant="base"
                onClick={onButtonClick}
                label={t('text_65e5a7e595d21700dc98c330')}
                color="primary"
                className="min-w-full mt-auto sm:min-w-min"
              />
            </div>
          </div>
        </div>
      </div>
    </ComponentLayout>
  );
}

export default Calculator;
