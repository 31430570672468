import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { pageMapper, storageKeys } from '../../utils/constants.ts';
import { getQueryParams } from '../../utils/helpers.ts';

function Redirect() {
  const { correlationId, hash } = getQueryParams();

  // TODO: temporarily redirect to the homepage till the backend
  // ready for the RBF flow
  const route = correlationId && hash
    ? pageMapper.home
    : pageMapper.home;

  useEffect(() => {
    if (hash) {
      sessionStorage.setItem(storageKeys.hash, hash);
    }

    if (correlationId) {
      sessionStorage.setItem(storageKeys.correlationId, correlationId);
    }
  }, []);

  return (
    <Navigate to={route} />
  );
}

export default Redirect;
