import { useRef, useSyncExternalStore } from 'react';

const useInView = (options: IntersectionObserverInit = {}) => {
  const ref = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver>();
  const intersectionRef = useRef(false);

  const subscribe = (callback: () => void) => {
    if (ref.current) {
      observerRef.current = new IntersectionObserver(([entry]) => {
        intersectionRef.current = entry?.isIntersecting ?? false;
        callback();
      }, options);

      observerRef.current.observe(ref.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  };

  const getSnapshot = () => intersectionRef.current;

  const isInView = useSyncExternalStore(subscribe, getSnapshot);

  return [ref, isInView] as const;
};

export default useInView;
