import React, { type ReactNode } from 'react';
import { cn, joinValues } from '../../utils/helpers';

const alignments = {
  top: 'items-start',
  center: 'items-center',
  bottom: 'items-end',
};

type Alignment = keyof typeof alignments;

type CellProps = {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  truncate?: boolean;
  beginAdornment?: ReactNode;
  endAdornment?: ReactNode;
  beginAdornmentPosition?: Alignment;
  endAdornmentPosition?: Alignment;
};

/**
 * A basic cell with an optional leading attribute
 *
 * @param children                A node specifying the content on the cell
 * @param className               String with css classes for the cell container
 * @param disabled                A boolean to disable the cell
 * @param truncate                A boolean to truncate the content
 * @param beginAdornment          Optional adornment shown at the end of the cell
 * @param endAdornment            Optional adornment shown at the end of the cell
 * @param beginAdornmentPosition  Optional vertical alignment of the adornment
 * @param endAdornmentPosition    Optional vertical alignment of the adornment
 */
function Cell({
  children,
  className,
  disabled,
  truncate,
  beginAdornment,
  endAdornment,
  beginAdornmentPosition = 'center',
  endAdornmentPosition = 'center',
}: CellProps) {
  const beginAdornmentAlignment = alignments[beginAdornmentPosition];
  const endAdornmentAlignment = alignments[endAdornmentPosition];

  return (
    <div
      className={joinValues({
        base: 'flex gap-6 justify-between items-center bg-transparent',
        options: className,
      })}
    >
      <div className={cn(beginAdornmentAlignment, 'flex gap-3')}>
        {beginAdornment && (
          <div className={cn(disabled && 'text-slate-400')}>
            {beginAdornment}
          </div>
        )}
        <div
          className={joinValues({
            base: 'pt-1',
            disabled: disabled && 'text-slate-400',
            options: truncate && 'truncate',
          })}
        >
          {children}
        </div>
      </div>
      {endAdornment && (
        <div className={cn(endAdornmentAlignment, disabled && 'text-slate-400')}>
          {endAdornment}
        </div>
      )}
    </div>
  );
}

export default Cell;
