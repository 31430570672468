import React from 'react';
import ComponentLayout from '../../components/layout/ComponentLayout.tsx';
import Header from '../../components/ui/Header.tsx';
import Text from '../../components/ui/Text.tsx';

function RabobankInfo() {
  return (
    <ComponentLayout className="flex-col pt-10 gap-2 lg:py-10">
      <div className="flex flex-col max-w-sm">
        <Header
          langKey="text_65a4a5af8a80c500a343f56e"
          element="h4"
        />
        <Text
          langKey="text_65a4a5af8a80c500a343f56f"
        />
      </div>
    </ComponentLayout>
  );
}

export default RabobankInfo;
