import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { pageMapper, restrictedPageOrder } from '../utils/constants.ts';
import { StoreContext } from '../utils/store';

const allowedPagesAfterFinish = [
  pageMapper.offerSuccess,
  pageMapper.feedbackSuccess,
];

function AppGate({ children }) {
  const { state } = useContext(StoreContext);
  const location = useLocation();

  const { requester, flowFinished } = state;
  const { kvkNumber } = requester;

  const { pathname } = location;
  const currLocationId = restrictedPageOrder[pathname];

  const isRestricted = Boolean(currLocationId);
  const isFinishedPage = allowedPagesAfterFinish.includes(pathname);

  if (isRestricted && !kvkNumber) {
    window.location.replace(pageMapper.home);
  }

  if (flowFinished && !isFinishedPage) {
    sessionStorage.clear();
    window.location.href = '/';
  }

  return children;
}

export default AppGate;
