import { useEffect, useState } from 'react';

const DESKTOP_WIDTH = 1024;

function useIsDesktop() {
  const [isMobile, setIsMobile] = useState(window.innerWidth >= DESKTOP_WIDTH);

  useEffect(() => {
    const handleChange = () => {
      setIsMobile(window.innerWidth >= DESKTOP_WIDTH);
    };

    handleChange();

    window.addEventListener('resize', handleChange);

    return () => {
      window.removeEventListener('resize', handleChange);
    };
  }, []);

  return isMobile;
}

export default useIsDesktop;
