import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '../../utils/helpers';

type NavigationItemLineProps = {
  className?: string;
};

function NavigationItemLine({ className }: NavigationItemLineProps) {
  return (
    <motion.div className={cn('flex w-full', className)} layoutId="line">
      <div className="border-t border-solid border-blue-background w-full h-px mr-px -mt-[0.5px]" />
      <div className="size-2 border-b border-r border-blue-background rotate-45 shrink-0 -translate-y-1/2 mt-[0.5px]" />
      <div className="border-t border-solid border-blue-background w-full h-px ml-px -mt-[0.5px]" />
    </motion.div>
  );
}

export default NavigationItemLine;
