import React from 'react';
import { joinValues } from '../../utils/helpers';
import ChevronBold from '../../public/icons/chevron_bold.svg';
import ChevronThin from '../../public/icons/chevron_thin.svg';
import ChevronUltraThin from '../../public/icons/chevron_ultra_thin.svg';

const variants = {
  ultra_thin: ChevronUltraThin,
  thin: ChevronThin,
  bold: ChevronBold,
};

const directions = {
  up: 'rotate-180',
  down: '',
  left: 'rotate-90',
  right: '-rotate-90',
};

const colors = {
  secondary: 'fill-secondary',
  blue: 'fill-blue-dark',
  white: 'fill-white',
  black: 'fill-off-black',
  primary: 'fill-primary',
  gray: 'fill-gray-500',
};

export type ChevronVariants = keyof typeof variants;

export type ChevronColors = keyof typeof colors;

type ChevronProps = {
  className?: string;
  variant?: ChevronVariants;
  direction?: keyof typeof directions;
  color?: keyof typeof colors;
  disabled?: boolean;
};

function Chevron({
  className,
  direction,
  disabled,
  color = 'secondary',
  variant = 'thin',
}: ChevronProps) {
  const ChevronIcon = variants[variant];

  return (
    <ChevronIcon
      className={joinValues({
        base: 'text-off-black transition-all',
        options: className,
        color: disabled ? 'fill-gray-dark' : colors[color],
        direction: direction && directions[direction],
      })}
    />
  );
}

export default Chevron;
