import React, { forwardRef, type ReactNode } from 'react';
import { motion } from 'framer-motion';
import { A11y, Pagination } from 'swiper/modules';
import { Swiper as SwiperComponent, type SwiperProps, type SwiperRef } from 'swiper/react';
import { Swiper } from 'swiper/types';
import { cn, transition } from '../../utils/helpers';

import 'swiper/css';
import 'swiper/css/pagination';
import '../../styles/carousel.css';

type CarouselProps = {
  children: ReactNode;
  slidesPerView?: SwiperProps['slidesPerView'];
  gap?: SwiperProps['spaceBetween'];
  isCentered?: boolean;
  className?: string;
  onSlideChange?: (swiper: Swiper) => void;
  wrapperClass?: string;
  showPagination?: boolean;
  name: string;
};

const Carousel = forwardRef<SwiperRef, CarouselProps>(({
  children,
  onSlideChange = () => {},
  gap = 24,
  slidesPerView = 1,
  isCentered = true,
  className,
  name,
  wrapperClass,
  showPagination = true,
}, ref) => (
  <motion.div
    className={cn('w-full', className)}
    transition={transition}
    layoutId={`${name}_carousel`}
  >
    <SwiperComponent
      ref={ref}
      navigation
      noSwipingClass="input-view"
      modules={[Pagination, A11y]}
      spaceBetween={gap}
      slidesPerView={slidesPerView}
      wrapperClass={wrapperClass}
      centeredSlides={isCentered}
      onSlideChange={onSlideChange}
      pagination={showPagination && {
        clickable: true,
        bulletClass: 'carousel-pagination-bullet',
        bulletActiveClass: 'carousel-pagination-bullet-active',
        renderBullet: (index, styling) => {
          const ariaLabel = `Go to slide ${index + 1}`;

          return `<span class=${styling} tabIndex="0" role="button" aria-label=${ariaLabel}><span class="inner"></span></span>`;
        },
      }}
    >
      {children}
    </SwiperComponent>
  </motion.div>
));

export default Carousel;
