import React from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { pageMapper } from '../../utils/constants.ts';
import { cn, joinValues } from '../../utils/helpers.ts';
import Link from '../ui/Link.tsx';
import Text from '../ui/Text.tsx';

function NavigationContent({ isOpen, setIsOpen, distance = 0 }) {
  const location = useLocation();

  const pages = [
    {
      page: pageMapper.home,
      langKey: 'home',
    },
    {
      page: pageMapper.faq,
      langKey: 'faq',
    },
    {
      page: pageMapper.contact,
      langKey: 'contact',
    },
  ];

  return (
    <>
      {/* Mobile */}
      <nav
        className={joinValues({
          base: 'fixed flex flex-col gap-6 top-0 left-0 w-full p-10 h-screen pt-24 bg-white',
          desktop: 'lg:hidden',
          transition: 'transform delay-100 transition-all duration-300',
          open: isOpen ? 'translate-x-0 sm:translate-x-2/4' : 'delay-300 translate-x-full',
        })}
      >
        <ul className="flex flex-col items-start w-full gap-6">
          {pages.map(({ page, langKey }) => {
            const isActivePage = location.pathname === page;
            const color = isActivePage ? 'primary' : 'secondary';

            return (
              <li key={page}>
                <Link
                  onClick={() => setIsOpen(false)}
                  href={page}
                  disabled={isActivePage}
                >
                  <Text
                    langKey={`navigation.${langKey}`}
                    element="nav"
                    size="lg"
                    weight="semibold"
                    color={color}
                    className="hover:text-[inherit]"
                  />
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>

      {/* Desktop */}
      <nav
        className={joinValues({
          base: 'hidden -mr-8 border-inherit',
          desktop: 'lg:flex',
        })}
      >
        <ul className="flex w-full gap-6 transition-colors duration-500 border-inherit">
          {pages.map(({ page, langKey }) => {
            const isActivePage = location.pathname === page;

            return (
              <li key={page} className="flex flex-col border-inherit">
                <Link href={page} disabled={isActivePage}>
                  <Text
                    weight="semibold"
                    size="sm"
                    langKey={`navigation.${langKey}`}
                    color="custom"
                    className={cn('text-inherit')}
                  />
                </Link>
                {location.pathname === page && (
                  <motion.div
                    className="hidden -mt-0.5 border border-solid border-inherit lg:block"
                    layoutId="underline"
                    transition={{ duration: distance === 0 ? 0.2 : 0 }}
                  />
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
}

export default NavigationContent;

NavigationContent.defaultProps = {
  distance: 0,
};

NavigationContent.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  distance: PropTypes.number,
};
