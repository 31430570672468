import React from 'react';
import { joinValues, transition } from '../../utils/helpers.ts';
import ComponentLayout from '../layout/ComponentLayout.tsx';
import CircleText from '../ui/CircleText.tsx';
import Header from '../ui/Header.tsx';
import Text from '../ui/Text.tsx';
import Accordion from './Accordion.tsx';

function ProcessSteps() {
  const component = 'process_steps.factuur_financiering';
  const image = 'bg-right bg-cover bg-process_steps bg-no-repeat bg-blend-multiply';

  const stepKeys = [
    {
      id: 1,
      question: 'text_65a4842dab1a0c007b58f4cd',
      answer: 'text_65a4842dab1a0c007b58f4ce',
    },
    {
      id: 2,
      question: 'text_65a4842dab1a0c007b58f4d0',
      answer: 'text_65a4842dab1a0c007b58f4d1',
    },
    {
      id: 3,
      question: 'text_65a4842dab1a0c007b58f4d3',
      answer: 'text_65a4842dab1a0c007b58f4d4',
    },
    {
      id: 4,
      question: 'text_65a4842dab1a0c007b58f4d6',
      answer: 'text_65a4842dab1a0c007b58f4d7',
    },
    {
      id: 5,
      question: 'text_65a4842dab1a0c007b58f4d9',
      answer: 'text_65a4842dab1a0c007b58f4da',
    },
  ];

  return (
    <ComponentLayout
      transition={transition}
      layoutId={component}
      className="justify-center w-full bg-gray-background lg:pr-0 xl:pr-0"
      variant="full"
    >
      <div
        className={joinValues({
          base: 'flex flex-col w-full',
          md: 'md:flex-row-reverse',
        })}
      >
        <div
          className={joinValues({
            background: image,
            base: 'flex w-full h-96',
            md: 'md:h-[576px]',
            lg: 'lg:h-[768px]',
          })}
        />
        <div
          className={joinValues({
            base: 'flex flex-col items-center w-full gap-10 pt-16 pb-8 px-6',
            sm: 'sm:px-20',
            lg: 'lg:max-w-component lg:gap-16 lg:py-16 lg:pr-24 lg:pl-0',
          })}
        >
          <Header
            langKey="text_65a4842dab1a0c007b58f49b"
            className="max-w-2xl text-center"
            element="h2"
          />
          <div className="flex flex-col gap-6 max-w-md">
            {stepKeys.map((step) => {
              const { question, answer } = step;

              const adornment = (
                <CircleText className="-mt-0.5 lg:mt-[3px]">
                  {step.id}
                </CircleText>
              );

              return (
                <Accordion
                  langKey={question}
                  size="h4"
                  key={step.id}
                  beginAdornment={adornment}
                >
                  <Text langKey={answer} />
                </Accordion>
              );
            })}
          </div>
        </div>
      </div>
    </ComponentLayout>
  );
}

export default ProcessSteps;
