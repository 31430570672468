import React from 'react';
import { measureEligibilityModal } from '../../utils/analytics';
import ModalLayout from '../layout/ModalLayout';
import Header from '../ui/Header.tsx';
import Text from '../ui/Text.tsx';
import ELigibilityTerms from './EligibiltyTerms';

function EligibiltyCheckModal({ setIsOpen }) {
  const component = 'eligibility_terms.factuur_financiering';
  measureEligibilityModal();

  const term1 = {
    question: 'text_65ca4ad7e7f2a300be6e690c',
    answers: ['text_65ca4ad7e7f2a300be6e690d',
      'text_65ca4ad7e7f2a300be6e690e',
      'text_65ca4ad7e7f2a300be6e690f',
      'text_65ca4ad7e7f2a300be6e6910',
      'text_65ca4ad7e7f2a300be6e6911',
      'text_65ca4ad7e7f2a300be6e6912',
      'text_65ca4ad7e7f2a300be6e6913',
    ],
  };

  const term2 = {
    question: 'text_65ca4ad7e7f2a300be6e6914',
    answers: [
      'text_65ca4ad7e7f2a300be6e6915',
      'text_65ca4ad7e7f2a300be6e6916',
      'text_65ca4ad7e7f2a300be6e6917',
    ],
  };

  return (
    <ModalLayout>
      <ModalLayout.CloseButton
        onClose={() => setIsOpen(false)}
        name={`${component}_close`}
      />
      <div className="flex flex-col items-center gap-4 text-center text-base w-full">
        <Header
          langKey="text_65ca4ad7e7f2a300be6e690a"
          element="h1"
          className="max-w-lg"
        />
        <Text
          langKey="text_65ca4ad7e7f2a300be6e690b"
          size="md"
          className="max-w-lg"
        />
        <ELigibilityTerms question={term1.question} answers={term1.answers} />
        <ELigibilityTerms question={term2.question} answers={term2.answers} />
      </div>
    </ModalLayout>
  );
}

export default EligibiltyCheckModal;
