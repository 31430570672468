import React from 'react';
import PropTypes from 'prop-types';
import { cn, joinValues, transition } from '../../utils/helpers.ts';
import ComponentLayout from '../layout/ComponentLayout.tsx';
import Divider from '../ui/Divider.tsx';
import Header from '../ui/Header.tsx';
import AbnLarge from '../../public/icons/bank/abn_lg.svg';
import AbnSmall from '../../public/icons/bank/abn_sm.svg';
import AsnLarge from '../../public/icons/bank/asn_lg.svg';
import AsnSmall from '../../public/icons/bank/asn_sm.svg';
import BunqLarge from '../../public/icons/bank/bunq_lg.svg';
import BunqSmall from '../../public/icons/bank/bunq_sm.svg';
import IngLarge from '../../public/icons/bank/ing_lg.svg';
import IngSmall from '../../public/icons/bank/ing_sm.svg';
import KnabLarge from '../../public/icons/bank/knab_lg.svg';
import KnabSmall from '../../public/icons/bank/knab_sm.svg';
import RabobankLarge from '../../public/icons/bank/rabobank_lg.svg';
import RabobankSmall from '../../public/icons/bank/rabobank_sm.svg';
import RegiobankLarge from '../../public/icons/bank/regiobank_lg.svg';
import RegiobankSmall from '../../public/icons/bank/regiobank_sm.svg';
import SnsLarge from '../../public/icons/bank/sns_lg.svg';
import SnsSmall from '../../public/icons/bank/sns_sm.svg';
import TriodosLarge from '../../public/icons/bank/triodos_lg.svg';
import TriodosSmall from '../../public/icons/bank/triodos_sm.svg';

export const supportedBankVariant = {
  base: 'base',
  side: 'side',
};

const component = 'supported_banks';

function IconSmall({ Icon }) {
  return (
    <Icon className="w-8" />
  );
}

function Base({ className }) {
  return (
    <ComponentLayout
      transition={transition}
      layoutId={component}
      className={joinValues({
        options: className,
        base: 'flex-col items-center gap-4 py-10 text-center',
        sm: 'sm:py-20 sm:gap-6',
        xl: 'xl:px-24',
      })}
    >
      <Header
        langKey="text_65a4842dab1a0c007b58f482"
        size="h4"
        element="h2"
      />
      <div
        className={joinValues({
          base: 'hidden flex-wrap justify-evenly items-center gap-10',
          sm: 'sm:flex',
        })}
      >
        <RabobankLarge />
        <AbnLarge />
        <IngLarge />
        <RegiobankLarge />
        <AsnLarge />
        <TriodosLarge />
        <SnsLarge />
        <KnabLarge />
        <BunqLarge />
      </div>

      <div className="flex flex-col gap-6 sm:hidden">
        <div className="flex flex-wrap gap-10 justify-start">
          <IconSmall Icon={RabobankSmall} />
          <IconSmall Icon={AbnSmall} />
          <IconSmall Icon={AsnSmall} />
          <IconSmall Icon={IngSmall} />
          <IconSmall Icon={BunqSmall} />
        </div>
        <div className="flex flex-wrap gap-10 justify-start">
          <IconSmall Icon={KnabSmall} />
          <IconSmall Icon={RegiobankSmall} />
          <IconSmall Icon={SnsSmall} />
          <IconSmall Icon={TriodosSmall} />
        </div>
      </div>
    </ComponentLayout>
  );
}

function Side({ className }) {
  return (
    <div className={cn(className, 'flex flex-col gap-4 bg-white rounded pt-5 pb-6')}>
      <Header
        langKey="text_65ae413afd96b700aeb2aa8b"
        element="h3"
        size="h5"
        className="px-8"
      />

      <Divider className="mt-1" />

      <div className="flex flex-col gap-6 px-8">
        <div className="flex flex-wrap justify-start gap-8">
          <IconSmall Icon={RabobankSmall} />
          <IconSmall Icon={AbnSmall} />
          <IconSmall Icon={AsnSmall} />
          <IconSmall Icon={IngSmall} />
          <IconSmall Icon={BunqSmall} />
        </div>
        <div className="flex flex-wrap justify-start gap-8">
          <IconSmall Icon={KnabSmall} />
          <IconSmall Icon={RegiobankSmall} />
          <IconSmall Icon={SnsSmall} />
          <IconSmall Icon={TriodosSmall} />
        </div>
      </div>
    </div>
  );
}

function SupportedBanks({ className, variant }) {
  const variantMapper = {
    [supportedBankVariant.side]: <Side className={className} />,
    [supportedBankVariant.base]: <Base className={className} />,
  };

  return variantMapper[variant];
}

export default SupportedBanks;

SupportedBanks.defaultProps = {
  variant: supportedBankVariant.base,
  className: '',
};

SupportedBanks.propTypes = {
  variant: PropTypes.oneOf(Object.values(supportedBankVariant)),
  className: PropTypes.string,
};
