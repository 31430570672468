import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import usePageMeasure from '../../hooks/usePageMeasure.tsx';
import { pageMapper } from '../../utils/constants.ts';
import ContactCards from '../../components/container/ContactCards';
import Footer from '../../components/container/Footer.tsx';
import ComponentLayout from '../../components/layout/ComponentLayout.tsx';
import Button from '../../components/ui/Button.tsx';
import Header from '../../components/ui/Header.tsx';
import Text from '../../components/ui/Text.tsx';
import RabobankInfo from './RabobankInfo';
import WordPartner from './WordPartner';

function Contact() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  usePageMeasure({ name: 'contact' });
  const component = 'contact';

  return (
    <>
      <ComponentLayout className="flex flex-col items-center py-10 gap-10 lg:pt-20 lg:gap-20">
        <div className="flex flex-col items-center gap-6 max-w-xl lg:gap-10">
          <div className="flex flex-col gap-2 text-center lg:gap-0">
            <Header
              className="lg:text-5xl"
              langKey="text_65a4a5af8a80c500a343f563"
              size="h2"
              element="h1"
            />
            <Text
              langKey="text_65a4a5af8a80c500a343f564"
            />
          </div>

          <Button
            name={`${component}_open_modal`}
            label={t('text_65a4a5af8a80c500a343f565')}
            className="w-full sm:w-fit"
            variant="outline"
            onClick={() => navigate(pageMapper.faq)}
          />
        </div>

        <ContactCards />
      </ComponentLayout>

      <WordPartner />
      <RabobankInfo />
      <Footer />
    </>
  );
}

export default Contact;
