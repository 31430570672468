import api from '../utils/api.ts';

export default function sendMessage({ name, information, email, subject }) {
  const path = 'dls-contact-details/v1/contact-form';

  const body = {
    email,
    information,
    name,
    subject,
  };

  return api.post({ path, body });
}
