import { useEffect, useRef } from 'react';

function usePrevious(value, skip) {
  const ref = useRef();

  useEffect(() => {
    if (skip) {
      return;
    }

    ref.current = value;
  }, [value]);

  return ref.current;
}
export default usePrevious;
