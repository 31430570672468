import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { pageMapper } from '../utils/constants';
import AppContainer from '../pages/AppContainer';
import Contact from '../pages/Contact';
import Faq from '../pages/Faq';
import GetOffers from '../pages/GetOffers';
import Home from '../pages/Home';
import Redirect from '../pages/Redirect';

const CompareProducts = lazy(() => import('../pages/CompareProducts'));
const ShortTermLoanLandingPage = lazy(() => import('../pages/KortlopendeLening'));
const OverJou = lazy(() => import('../pages/OverJou'));
const Facturen = lazy(() => import('../pages/Facturen'));
const Transacties = lazy(() => import('../pages/Transacties'));
const Cookies = lazy(() => import('../pages/Cookies'));
const TransactiesVerzamelen = lazy(() => import('../pages/TransactiesVerzamelen'));
const NewToTheBank = lazy(() => import('../pages/NewToTheBank'));
const RequestFailed = lazy(() => import('../pages/RequestFailed'));
const Offer = lazy(() => import('../pages/Offer'));
const OfferSuccess = lazy(() => import('../pages/OfferSuccess'));
const FeedbackSuccess = lazy(() => import('../pages/FeedbackSuccess'));
const NotFound = lazy(() => import('../pages/NotFound'));
const TransactionSuccess = lazy(() => import('../pages/TransactionSuccess'));
const Redirection = lazy(() => import('../pages/Redirection'));

export default function Pages() {
  return (
    <Routes>
      <Route path={pageMapper.home} element={<AppContainer />}>
        <Route index element={<Home />} />
        <Route path={pageMapper.compareProducts} element={<CompareProducts />} />
        <Route path={pageMapper.shorttermLoan} element={<ShortTermLoanLandingPage />} />
        <Route path={pageMapper.cookies} element={<Cookies />} />
        <Route path={pageMapper.redirect} element={<Redirect />} />

        <Route path={pageMapper.contact} element={<Contact />} />
        <Route path={pageMapper.faq} element={<Faq />} />
        <Route path={pageMapper.aboutYou} element={<OverJou />} />
        <Route path={pageMapper.invoices} element={<Facturen />} />
        <Route path={pageMapper.transactions} element={<Transacties />} />
        <Route path={pageMapper.getTransactions} element={<TransactiesVerzamelen />} />
        <Route path={pageMapper.newToTheBank} element={<NewToTheBank />} />
        <Route path={pageMapper.requestFailed} element={<RequestFailed />} />
        <Route path={pageMapper.getOffers} element={<GetOffers />} />
        <Route path={pageMapper.offer} element={<Offer />} />
        <Route path={pageMapper.offerSuccess} element={<OfferSuccess />} />
        <Route path={pageMapper.feedbackSuccess} element={<FeedbackSuccess />} />

        <Route path="*" element={<NotFound />} />
      </Route>

      <Route path={`${pageMapper.redirection}`} element={<Redirection />} />
      <Route path={`${pageMapper.transactionSuccess}`} element={<TransactionSuccess />} />
    </Routes>
  );
}
