import { isAxiosError } from 'axios';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { storageKeys } from './constants';
import { statusErrors, unknownError } from './errors';

const DEFAULT_ANIMATION_DURATION = 0.4;

const SIX_MONTHS = 6;

const MINUTE_IN_SECONDS = 60;

const UNMASKED_NUMBERS = 3;

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const joinValues = (classes: Record<string, string | boolean | undefined>) => {
  if (!classes) {
    return '';
  }

  return Object.values(classes).filter(Boolean).join(' ');
};

const cookieKeys = {
  cookieConsent: 'cookie_consent',
  tester: 'tester',
  RABO_PSL: 'RABO_PSL',
} as const;

type CookieKey = typeof cookieKeys[keyof typeof cookieKeys];

/**
 * The regex approach is not only the fastest in most browsers, it
 * yields the shortest function as well. Additionally it should be
 * pointed out that according to the official spec (RFC 2109), the
 * space after the semicolon which separates cookies in the
 * document.cookie is optional and an argument could be made that
 * it should not be relied upon. Additionally, whitespace is
 * allowed before and after the equals sign (=) and an argument
 * could be made that this potential whitespace should be factored
 * into any reliable document.cookie parser. The regex above
 * accounts for both of the above whitespace conditions.
 *
 * link: https://stackoverflow.com/a/25490531
 *
 * @param {string} key    Value representing the key of the cookie
 * @returns               The value of the given key
 */
export const getCookieValue = (key: CookieKey) => (
  document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`)?.pop() || undefined
);

type CookieValueType = {
  [cookieKeys.cookieConsent]: boolean;
  [cookieKeys.RABO_PSL]: string;
  [cookieKeys.tester]: boolean;
};

export type CookieType<K extends CookieKey> = {
  name: K;
  value: CookieValueType[K];
};

type CookieOptions = {
  path: string;
  samesite: 'Strict' | 'Lax' | 'None';
  expires?: string;
  secure?: boolean;
};

type SetCookieArgs<K extends CookieKey> = CookieType<K> & {
  expires?: Date;
};

export const setCookie = <K extends CookieKey>({
  value,
  name,
  expires,
}: SetCookieArgs<K>) => {
  const newOptions: CookieOptions = {
    path: '/',
    samesite: 'Lax',
    secure: true,
  };

  if (expires) {
    newOptions.expires = expires.toUTCString();
  }

  const optionValues = Object.entries(newOptions)
    .map(([key, oValue]) => (oValue === true ? key : `${key}=${oValue}`))
    .join(';');

  const baseValue = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
  document.cookie = `${baseValue};${optionValues}`;
};

/**
 * The expiration period for consent of cookies is set to 6 months
 *
 * @param {boolean} approve     Sets the consent accordingly
 */
export const setCookieConsent = (approve: boolean) => {
  const expireDate = new Date();
  expireDate.setMonth(expireDate.getMonth() + SIX_MONTHS);

  setCookie({
    name: 'cookie_consent',
    value: approve,
    expires: expireDate,
  });

  setCookie({
    name: 'RABO_PSL',
    value: '3',
    expires: expireDate,
  });
};
export const clearSession = () => {
  Object.values(storageKeys).forEach((key) => sessionStorage.removeItem(key));
};

export const getQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  return Object.fromEntries(urlSearchParams.entries());
};

type LoopArgs = {
  fn: () => Promise<boolean> | boolean;
  delay: number;
  limit: number;
  callback?: () => void;
};

export const loop = ({ fn, delay, limit = 1, callback = () => { } }: LoopArgs) => {
  let prevIsActive = false;

  let interval: ReturnType<typeof setInterval>;
  let count = 0;

  const handleCallback = async () => {
    if (prevIsActive) {
      return;
    }

    prevIsActive = true;
    count += 1;

    const isFinished = await fn();
    prevIsActive = false;

    if (count >= limit) {
      callback();
    }

    if (isFinished || count >= limit) {
      clearInterval(interval);
    }
  };

  interval = setInterval(handleCallback, delay);

  return interval;
};

export const toInt = (value: string) => Number(value.replace(/[^0-9,-]+/g, ''));

export const toDateString = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const newDate = new Date(date.getTime() - offset * MINUTE_IN_SECONDS * 1000);

  const [convertedDate] = newDate.toISOString().split('T');
  return convertedDate;
};

export const toCurrency = (value: number) => new Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 0,
}).format(value);

export const toNormalizedString = (value: string) => value
  .normalize('NFD')
  .replace(/\p{Diacritic}/gu, '')
  .trim()
  .toLowerCase();

export const formatPercentage = (value: number, digits = 2) => (
  new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(value)
);

export const transitionDuration = DEFAULT_ANIMATION_DURATION;

export const transition = {
  duration: transitionDuration,
  ease: [0.36, 0.66, 0.04, 1],
};

export const getStatusError = (error: unknown): string => {
  if (!isAxiosError(error)) {
    return unknownError;
  }

  const status = error.response?.status;
  const errorKey = status != null && statusErrors[status];

  return errorKey || unknownError;
};

type FormatIbanArgs = {
  iban: string;
  isMasked?: boolean;
};

export const formatIban = ({ iban, isMasked = false }: FormatIbanArgs) => {
  const ibanToMask = iban.slice(0, iban.length - UNMASKED_NUMBERS);
  const unmaskedDigits = iban.slice(-UNMASKED_NUMBERS);

  if (iban.length !== 18) {
    return isMasked
      ? `...${unmaskedDigits}`
      : iban;
  }

  const placeholder = isMasked
    ? ibanToMask.replace(/\d/g, '*') + unmaskedDigits
    : iban;

  const countryCode = placeholder.slice(0, 2);
  const checkDigits = placeholder.slice(2, 4);
  const bankCode = placeholder.slice(4, 8);
  const number1 = placeholder.slice(8, 12);
  const number2 = placeholder.slice(12, 16);
  const number3 = placeholder.slice(16, 18);

  return `${countryCode}${checkDigits} ${bankCode} ${number1} ${number2} ${number3}`;
};

export const isBrowser = typeof window !== 'undefined';
