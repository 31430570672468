import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAxiosError } from 'axios';
import { companySearch } from '../services/invoice-finance-api';
import { type CompanyError, responseErrors } from '../utils/errors';
import { getStatusError, toNormalizedString } from '../utils/helpers';

export type CompanyOption = {
  kvkNumber: string;
  id: string;
  title: string;
  description: string[];
  error?: CompanyError;
};

type UseCompanySearchArgs = {
  searchValue?: string | CompanyOption;
  delay?: number;
};

function useCompanySearch({ searchValue, delay = 500 }: UseCompanySearchArgs) {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState('');

  const [companies, setCompanies] = useState<CompanyOption[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      if (typeof searchValue !== 'string') {
        return;
      }

      if (decodeURI(searchValue).trim().length < 3) {
        setCompanies([]);

        return;
      }

      setError('');
      setIsFetching(true);

      try {
        const value = toNormalizedString(searchValue);
        const { data } = await companySearch(value);

        const options = data.map((company) => {
          const {
            kvkNumber,
            name,
            street,
            homeNumber,
            id,
            postalCode,
            city,
          } = company;

          const address1 = street && homeNumber
            ? `${street} ${homeNumber}`
            : '';

          const address2 = [postalCode, city]
            .filter(Boolean)
            .join(', ');

          const optionId = id ?? Object.values(company)
            .filter(Boolean)
            .join('_');

          const option: CompanyOption = {
            kvkNumber,
            id: optionId,
            title: name,
            description: [kvkNumber],
          };

          if (address2) {
            option.description.unshift(address2);
          } else {
            option.error = 'INVALID_KVK_NUMBER';
          }

          if (address1) {
            option.description.unshift(address1);
          } else {
            option.error = 'INVALID_ADDRESS';
          }

          return option;
        });

        setCompanies(options);
      } catch (err) {
        if (!isAxiosError(err)) {
          return;
        }

        const code = err?.response?.data?.code?.toLowerCase();
        const isInvalidInput = code?.includes('invalidinput');

        const { ALPHANUMERIC } = responseErrors.company;
        const errorKey = isInvalidInput ? ALPHANUMERIC : getStatusError(err);

        setError(t(`error.${errorKey}`));
      } finally {
        setIsFetching(false);
      }
    };

    const timeout = setTimeout(fetchData, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchValue, delay]);

  return { companies, isFetching, error };
}

export default useCompanySearch;
