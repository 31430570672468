import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { NonEligibilityReason } from '../../services/invoice-finance-api';
import { measureLinkClick } from '../../utils/analytics';
import { pageMapper } from '../../utils/constants';
import Button from '../ui/Button';
import Header from '../ui/Header';
import Text from '../ui/Text';
import CatInTree from '../../public/icons/raboIcons/cat_in_tree.svg';

type EligibilityFailProps = {
  setIsOpen: (value: boolean) => void;
  error?: NonEligibilityReason;
};

type SetIsOpen = Pick<EligibilityFailProps, 'setIsOpen'>;

function InvalidSbiCode({ setIsOpen }: SetIsOpen) {
  const { t } = useTranslation();
  const component = 'Sector rejection';

  const onHomeClick = () => {
    measureLinkClick({ value: `${component} - Home` });
    setIsOpen(false);
    window.location.replace('/');
  };

  const onOtherClick = () => {
    measureLinkClick({ value: `${component} - Other options` });
    setIsOpen(false);
    window.location.replace(t('text_65c77497daffdb0078661836'));
  };

  return (
    <div className="flex flex-col items-center gap-6">
      <div className="flex flex-col items-center gap-4 text-center">
        <CatInTree />
        <Header
          langKey="text_65c6c12207a7ab00fe375da6"
          element="h4"
        />
        <Text
          langKey="text_65c6c12207a7ab00fe375da7"
          size="sm"
        />
      </div>
      <Button
        name={`${component}_back_to_home`}
        label={t('text_65c6c12207a7ab00fe375da8')}
        onClick={onHomeClick}
        color="primary"
        className="w-full"
      />
      <Button
        name={`${component}_other_products`}
        label={t('text_65c6c12207a7ab00fe375da9')}
        onClick={onOtherClick}
        color="secondary"
        variant="outline"
        className="w-full"
      />
    </div>
  );
}

function InvalidLegalStructure({ setIsOpen }: SetIsOpen) {
  const { t } = useTranslation();
  const component = 'Business type rejection';

  const onHomeClick = () => {
    measureLinkClick({ value: `${component} - Home` });
    setIsOpen(false);
    window.location.replace('/');
  };

  const onOtherClick = () => {
    measureLinkClick({ value: `${component} - Other options` });
    setIsOpen(false);
    window.open(t('text_65d272925af2c800d2a4ae59'), '_blank');
  };

  return (
    <div className="flex flex-col items-center gap-6">
      <div className="flex flex-col items-center gap-4 text-center">
        <CatInTree />
        <Header
          langKey="text_65d272925af2c800d2a4ae51"
          element="h4"
        />
        <Text
          langKey="text_65d272925af2c800d2a4ae52"
          size="sm"
        />
      </div>
      <Button
        name={`${component}_back_to_home`}
        label={t('text_65d272925af2c800d2a4ae53')}
        onClick={onHomeClick}
        color="primary"
        className="w-full"
      />
      <Button
        name={`${component}_other_products`}
        label={t('text_65d272925af2c800d2a4ae54')}
        onClick={onOtherClick}
        color="secondary"
        variant="outline"
        className="w-full"
      />
    </div>
  );
}

function InvalidFoundationDate({ setIsOpen }: SetIsOpen) {
  const { t } = useTranslation();
  const component = 'Starter rejection';

  const navigate = useNavigate();

  const onContinueClick = () => {
    measureLinkClick({ value: `${component} - Continue` });
    setIsOpen(false);
    navigate(pageMapper.aboutYou);
  };

  const onOtherClick = () => {
    measureLinkClick({ value: `${component} - Other options` });
    setIsOpen(false);
    window.open(t('text_65d272925af2c800d2a4ae5a'), '_blank');
  };

  return (
    <div className="flex flex-col items-center gap-6">
      <div className="flex flex-col items-center gap-4 text-center">
        <CatInTree />
        <Header
          langKey="text_65d272925af2c800d2a4ae55"
          element="h4"
        />
        <Text
          langKey="text_65d272925af2c800d2a4ae56"
          size="sm"
        />
      </div>
      <Button
        name={`${component}_continue`}
        label={t('text_65d272925af2c800d2a4ae57')}
        onClick={onContinueClick}
        color="primary"
        className="w-full"
      />
      <Button
        name={`${component}_other_products`}
        label={t('text_65d272925af2c800d2a4ae58')}
        onClick={onOtherClick}
        color="secondary"
        variant="outline"
        className="w-full"
      />
    </div>
  );
}

const rejectionMapper: Record<NonEligibilityReason, FC<SetIsOpen>> = {
  SBI_CODE_INVALID: InvalidSbiCode,
  LEGAL_STRUCTURE_INVALID: InvalidLegalStructure,
  FOUNDATION_DATE_CHECK: InvalidFoundationDate,
};

function EligibilityFail({ setIsOpen, error = 'SBI_CODE_INVALID' }: EligibilityFailProps) {
  const RejectionPage = rejectionMapper[error];

  return <RejectionPage setIsOpen={setIsOpen} />;
}

export default EligibilityFail;
