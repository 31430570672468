import { useEffect, useState } from 'react';
import axios from 'axios';

const useInterceptor = () => {
  const [tokenExpired, setTokenExpired] = useState(false);

  useEffect(() => {
    const resInterceptor = (res) => res;

    const errInterceptor = (error) => {
      setTokenExpired(error.response.status === 401);

      return Promise.reject(error);
    };

    const interceptor = axios.interceptors.response.use(resInterceptor, errInterceptor);

    return () => axios.interceptors.response.eject(interceptor);
  }, []);

  return [tokenExpired, setTokenExpired];
};

export default useInterceptor;
