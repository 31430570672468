import React, { forwardRef, type HTMLInputTypeAttribute, type ReactNode } from 'react';
import { type RegisterOptions, type UseFormRegisterReturn } from 'react-hook-form';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';
import { cn, transition } from '../../utils/helpers';
import ErrorMessage from './ErrorMessage';
import InfoIcon from './InfoIcon';
import Text from './Text';
import Tooltip from './Tooltip';

type InputVariant =
  | 'base'
  | 'underline';

export type Validation = Pick<RegisterOptions, 'required' | 'min' | 'max' | 'minLength' | 'maxLength' | 'pattern' | 'validate'>;

export type BaseInputProps = {
  readOnly?: boolean;
  autofill?: boolean;
  placeholder?: string;
};

export type InputContainerProps = {
  error?: string;
  variant?: InputVariant;
  children: ReactNode;
  name: string;
  className?: string;
  label?: string;
  disabled?: boolean;
  tooltip?: string;
};

export const InputContainer = forwardRef<HTMLDivElement, InputContainerProps>(({
  error,
  variant = 'base',
  children,
  name,
  className,
  label,
  disabled,
  tooltip,
}, ref) => {
  const hasError = Boolean(error);
  const Icon = <InfoIcon className="w-6" />;

  const borderColor = hasError
    ? 'border-error'
    : 'border-gray-300';

  const errorIcon = (
    <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
      <ExclamationCircleIcon className="w-5 h-5 text-error" aria-hidden="true" />
    </div>
  );

  return (
    <motion.div
      transition={transition}
      layoutId={`${name}_layout`}
      layout="position"
      ref={ref}
      className={className}
    >
      <div className="flex w-full gap-x-2 py-1">

        <label htmlFor={name} className="block pl-px text-sm font-light text-off-black text-left">
          {label}
        </label>
        {tooltip && (
          <Tooltip content={Icon}>
            {tooltip}
          </Tooltip>
        )}
      </div>

      <div
        className={cn(
          'relative group flex focus-within:border-secondary',
          disabled && 'bg-slate-50 text-slate-500 border-slate-200 shadow-none',
          variant === 'underline' && 'border-b',
          variant === 'base' && 'rounded shadow-sm border',
          '*:text-sm *:font-light [&>input]:truncate *:outline-none *:cursor-[inherit] *:rounded-[inherit]',
          '*:placeholder:text-sm *:placeholder:font-light',
          borderColor,
        )}
      >
        {children}

        {hasError && errorIcon}

        <div
          className={cn(
            'absolute -inset-px pointer-events-none group-focus-within:border-secondary',
            variant === 'underline' && 'group-focus-within:border-b-2',
            variant === 'base' && 'group-focus-within:border-2 rounded',
          )}
        />
      </div>

      <ErrorMessage name={name} error={error} />
    </motion.div>
  );
});

type InputProps = Omit<InputContainerProps, 'children'> & BaseInputProps & UseFormRegisterReturn & {
  type?: HTMLInputTypeAttribute;
  autoComplete?: string;
};

const Input = forwardRef<HTMLInputElement, InputProps>(({
  type = 'text',
  autoComplete = 'on',
  readOnly,
  autofill,
  placeholder,
  error,
  variant,
  className,
  label,
  ...register
}, ref) => {
  const { name, disabled } = register;
  const hasError = Boolean(error);

  return (
    <InputContainer
      className={className}
      error={error}
      label={label}
      variant={variant}
      name={name}
      disabled={disabled}
    >
      <input
        readOnly={readOnly}
        data-testid={name}
        id={name}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        {...register}
        ref={ref}
        className={cn(
          'w-full py-3 pl-4',
          autofill ? 'bg-blue-autocomplete' : 'bg-transparent',
          hasError ? 'pr-12' : 'px-4',
        )}
      />
    </InputContainer>
  );
});

type CurrencyInputProps = Omit<InputContainerProps, 'children'> & BaseInputProps & UseFormRegisterReturn & {
  defaultValue?: string;
  allowDecimals?: boolean;
  tooltip?: string;
};

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(({
  defaultValue,
  allowDecimals,
  readOnly,
  autofill,
  placeholder,
  error,
  variant,
  className,
  label,
  tooltip,
  ...register
}, ref) => {
  const { name, disabled } = register;
  const hasError = Boolean(error);

  return (
    <InputContainer
      className={className}
      error={error}
      label={label}
      variant={variant}
      name={name}
      disabled={disabled}
      tooltip={tooltip}
    >
      <Text
        className="absolute inset-y-0 left-0 flex items-center pl-4 text-gray-500 pointer-events-none"
        size="sm"
        color="custom"
      >
        €
      </Text>
      <NumericFormat
        readOnly={readOnly}
        data-testid={name}
        id={name}
        defaultValue={defaultValue}
        placeholder={placeholder}
        decimalSeparator=","
        thousandSeparator="."
        decimalScale={allowDecimals ? 2 : 0}
        allowNegative={false}
        {...register}
        getInputRef={ref}
        className={cn(
          'w-full py-3 pl-9',
          autofill ? 'bg-blue-autocomplete' : 'bg-transparent',
          hasError ? 'pr-12' : 'pr-4',
        )}
      />
    </InputContainer>
  );
});

type PhoneInputProps = Omit<InputContainerProps, 'children'> & BaseInputProps & UseFormRegisterReturn & {
  defaultValue?: string;
  autoComplete?: string;
};

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(({
  defaultValue,
  readOnly,
  autofill,
  placeholder,
  error,
  variant,
  className,
  label,
  autoComplete = 'on',
  ...register
}, ref) => {
  const { name, disabled } = register;
  const hasError = Boolean(error);

  return (
    <InputContainer
      className={className}
      error={error}
      label={label}
      variant={variant}
      name={name}
      disabled={disabled}
    >
      <PatternFormat
        readOnly={readOnly}
        data-testid={name}
        id={name}
        type="tel"
        defaultValue={defaultValue}
        placeholder={placeholder}
        format="##########"
        autoComplete={autoComplete}
        {...register}
        getInputRef={ref}
        className={cn(
          'w-full py-3 pl-4',
          autofill ? 'bg-blue-autocomplete' : 'bg-transparent',
          hasError ? 'pr-12' : 'pr-4',
        )}
      />
    </InputContainer>
  );
});

export default Input;
