import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { transition, transitionDuration } from '../../utils/helpers';
import Text from './Text';

export type RedirectionMessageProps = {
  header: string;
  text: string;
  link?: string;
  info?: string;
};

function RedirectionMessage({ header, text, link, info }: RedirectionMessageProps) {
  return (
    <AnimatePresence mode="wait">
      <motion.div
        layout="position"
        className="flex flex-col gap-2 px-4 py-3 rounded bg-blue-light"
        key="content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: transitionDuration } }}
        exit={{ opacity: 0 }}
        transition={transition}
      >
        <Text
          langKey={header}
          size="sm"
          weight="semibold"
          color="secondary"
        />
        <Text
          langKey={text}
          size="sm"
          color="secondary"
        />
        {link && (
          <Text
            langKey={link}
            size="sm"
            linkVariant="underline"
            weight="semibold"
            color="secondary"
          />
        )}
        {info && (
          <Text
            langKey={info}
            size="sm"
            color="secondary"
          />
        )}
      </motion.div>
    </AnimatePresence>
  );
}

export default RedirectionMessage;
