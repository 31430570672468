import React, { type ReactNode } from 'react';
import PropTypes from 'prop-types';
import { joinValues } from '../../utils/helpers';
import Button, { type ButtonColorKeys } from '../ui/Button';

type Theme = {
  bg: string;
  button: ButtonColorKeys;
};

export const infoWithButtonTheme: Record<string, Theme> = {
  BLUE_WHITE: {
    bg: 'bg-blue-light',
    button: 'secondary',
  },
  WHITE_BLUE: {
    bg: 'bg-white',
    button: 'secondary',
  },
};

type InfoWithButtonProps = {
  children: ReactNode;
  name: string;
  className?: string;
  onClick: () => void;
  theme?: Theme;
  buttonLabel: string;
};

function InfoWithButton({
  className,
  name,
  children,
  onClick,
  theme,
  buttonLabel,
}: InfoWithButtonProps) {
  const component = 'info_with_button.factuur_financiering';

  return (
    <div
      className={joinValues({
        base: 'w-full rounded',
        bg: theme?.bg,
        options: className,
      })}
    >
      <div
        className={joinValues({
          base: 'flex flex-col w-full py-8 px-6 gap-4 items-center',
          lg: 'lg:py-10 lg:gap-8',
        })}
      >
        {children}

        <Button
          name={`${component}_${name}`}
          variant="outline"
          onClick={onClick}
          label={buttonLabel}
          color={theme?.button}
          className="min-w-full mt-auto sm:min-w-min"
        />
      </div>
    </div>
  );
}

export default InfoWithButton;

InfoWithButton.defaultProps = {
  className: '',
  theme: infoWithButtonTheme.BLUE_WHITE,
};

InfoWithButton.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(Object.values(infoWithButtonTheme)),
  buttonLabel: PropTypes.string.isRequired,
};
