import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { isNonEligibilityReason, requesterEligibility } from '../../services/invoice-finance-api.ts';
import useCompanySearch from '../../hooks/useCompanySearch.tsx';
import { measureLinkClick } from '../../utils/analytics';
import { adornmentAlignment, pageMapper } from '../../utils/constants.ts';
import { getStatusError, joinValues, transition, transitionDuration } from '../../utils/helpers.ts';
import { StoreContext } from '../../utils/store';
import ComponentLayout from '../layout/ComponentLayout.tsx';
import Button from '../ui/Button.tsx';
import Cell from '../ui/Cell.tsx';
import ErrorMessage from '../ui/ErrorMessage.tsx';
import Header from '../ui/Header.tsx';
import Modal from '../ui/Modal.tsx';
import RedirectionMessage from '../ui/RedirectionMessage.tsx';
import Text from '../ui/Text.tsx';
import Checkmark from '../../public/icons/checkmark.svg';
import EligibilityFail from './EligibilityFail.tsx';
import EligibilityForm, { inputName } from './EligibilityForm';
import EligibiltyCheckModal from './EligibiltyCheckModal';
import InvalidCompany from './InvalidCompany';

function Eligibility({ className, usedCalculator }, ref) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [eligibiltyError, setEligibilityError] = useState();
  const [showKvkModal, setShowKvkModal] = useState(false);

  const [isInvalidCompany, setIsInvalidCompany] = useState(false);
  const [showEligibiltyCheckModal, setShowEligibiltyCheckModal] = useState(false);

  const [kvkError, setKvkError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { actions } = useContext(StoreContext);
  const { setRequester } = actions;

  const {
    control,
    handleSubmit,
    watch,
    formState,
    setError,
    register,
    clearErrors,
  } = useForm({ mode: 'onBlur' });

  const searchValue = watch(inputName) ?? '';
  const component = 'eligibility';

  const { errors, isValid } = formState;
  const { isFetching, companies, error } = useCompanySearch({ searchValue });

  const items = [
    'text_660d4dc9a73eab00612ee1b9',
    'text_660d4dc9a73eab00612ee1ba',
    'ff_usp_3',
  ];

  const onSubmit = async (formData) => {
    measureLinkClick({ value: 'Start je aanvraag' });
    setIsLoading(true);

    const company = searchValue.title;
    const { kvkNumber } = formData[inputName];

    try {
      const { data } = await requesterEligibility({ kvkNumber });
      const { eligible, newToTheBank } = data;

      // Only one eligible rejection screen can be shown
      // so the first valid eligibility error is used
      const errReason = data.nonEligibilityReasons?.find(isNonEligibilityReason);

      if (errReason) {
        setIsLoading(false);
      }

      setKvkError(errReason);
      setShowKvkModal(true);

      if (eligible) {
        setRequester({
          kvkNumber,
          newToTheBank,
          company,
        });

        if (!errReason) {
          navigate(pageMapper.aboutYou, { state: { usedCalculator } });
        }
      }
    } catch (err) {
      const errorKey = getStatusError(err);
      setEligibilityError(errorKey);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (error) {
      setError(inputName, { type: 'custom', message: error });

      return;
    }

    if (!errors[inputName]) {
      return;
    }

    clearErrors(inputName);
  }, [error]);

  useEffect(() => {
    if (typeof searchValue !== 'object') {
      return;
    }

    if (!searchValue.error) {
      return;
    }

    setIsInvalidCompany(true);
  }, [searchValue]);

  const redirectionMessageProps = {
    header: 'text_65af9fe50acc82516c5cb30e',
    text: 'text_65af9fe50acc82516c5cb30f',
  };

  const handleEligibilityTermsClick = () => {
    measureLinkClick({ value: 'Eligibility modal' });
    setShowEligibiltyCheckModal(true);
  };

  const companyChosen = Boolean(searchValue?.title);

  return (
    <LayoutGroup id={component}>
      <ComponentLayout
        layoutId="component_layout"
        transition={transition}
      >
        <div
          className={joinValues({
            base: 'relative rounded',
            lg: 'max-xl:max-w-sm lg:my-24',
            xl: 'xl:w-[50%] xl:rounded-none',
            options: className,
          })}
          ref={ref}
        >
          <motion.div
            className={joinValues({
              base: 'flex flex-col',
              spacing: 'px-6 py-6 gap-4',
              xl: 'xl:py-0 xl:pl-0 xl:pr-14 xl:gap-8',
            })}
            layout="position"
          >
            <Header
              langKey="text_65a4842dab1a0c007b58f47b"
              className="text-2xl xl:text-5xl xl:leading-[4rem] xl:mt-24"
              element="h1"
              size="custom"
            />
            <ul className="flex flex-col xl:pr-32 xl:gap-4">
              {items.map((bullet) => (
                <li key={bullet}>
                  <Cell
                    beginAdornment={<Checkmark className="mt-3 fill-secondary" />}
                    beginAdornmentPosition={adornmentAlignment.top}
                  >
                    <Text langKey={bullet} />
                  </Cell>
                </li>
              ))}
            </ul>

            <div className="flex flex-col gap-4 xl:gap-6 xl:pr-32">
              <EligibilityForm
                onSubmit={handleSubmit(onSubmit)}
                options={companies}
                register={register}
                isLoading={isFetching}
                error={errors}
                control={control}
                displayValue={(value) => value?.title}
                formName={component}
              />

              <AnimatePresence mode="wait">
                {companyChosen && (
                  <motion.div
                    layout="position"
                    key="invoice_message"
                    transition={transition}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { delay: transitionDuration } }}
                    exit={{ opacity: 0 }}
                  >
                    <RedirectionMessage {...redirectionMessageProps} />
                  </motion.div>
                )}
              </AnimatePresence>

              <motion.div
                layoutId="eligibility_button"
                layout="position"
                transition={transition}
              >
                <Button
                  label={t('text_65a4842dab1a0c007b58f481')}
                  className="w-full"
                  form={component}
                  name={`${component}_start`}
                  type="submit"
                  color="primary"
                  disabled={!isValid}
                  isLoading={isLoading}
                />
                <ErrorMessage
                  name={`${component}_error`}
                  errorKey={eligibiltyError}
                />
              </motion.div>

              <Button
                variant="link"
                onClick={handleEligibilityTermsClick}
                name="eligibilty_terms"
                label={t('text_65cb87e20b5873009d9ee36a')}
                className="w-full hover:text-primary text-base"
              />
            </div>
          </motion.div>

          {/* Shadow */}
          <div className="absolute top-0 left-0 w-full h-full bg-white rounded drop-shadow-rabobank -z-10 xl:hidden" />
        </div>
      </ComponentLayout>

      <Modal isOpen={showKvkModal} setIsOpen={setShowKvkModal} className="max-w-md">
        <EligibilityFail
          setIsOpen={setShowKvkModal}
          error={kvkError}
        />
      </Modal>

      <Modal isOpen={isInvalidCompany} setIsOpen={setIsInvalidCompany} className="max-w-md">
        <InvalidCompany
          setIsOpen={setIsInvalidCompany}
        />
      </Modal>

      <Modal isOpen={showEligibiltyCheckModal} setIsOpen={setShowEligibiltyCheckModal} className="max-w-4xl">
        <EligibiltyCheckModal
          setIsOpen={setShowEligibiltyCheckModal}
        />
      </Modal>
    </LayoutGroup>
  );
}

export default forwardRef(Eligibility);
