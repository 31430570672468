import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { joinValues, transition } from '../../utils/helpers';
import Eligibility from '../../components/container/Eligibility';

type EligibilityWithBackgroundProps = {
  className?: string;
  usedCalculator: boolean;
};

const EligibilityWithBackground = forwardRef<HTMLDivElement, EligibilityWithBackgroundProps>(({
  className,
  usedCalculator,
}, ref) => (
  <>
    <motion.div
      className={joinValues({
        base: 'flex items-center justify-center overflow-hidden',
        size: 'w-full min-h-[18rem] pt-[60%]',
        position: 'absolute left-0 top-0 -z-10',
        background: 'bg-cover bg-center bg-eligibility-md bg-no-repeat bg-blend-multiply',
        lg: 'lg:hidden',
        className,
      })}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={transition}
    />

    <div className="relative flex flex-col w-full max-w-sm gap-4 pt-[25%] pb-7 md:pt-[28%] lg:max-w-none lg:p-0 lg:gap-0">
      <motion.div
        className={joinValues({
          base: 'absolute hidden bottom-0 right-0 w-full -z-10 top-0',
          background: 'bg-eligibility-lg bg-no-repeat bg-blend-multiply',
          lg: 'lg:flex max-xl:bg-[left_0%_top_10%] max-xl:bg-[length:120%]',
          xl: 'xl:w-[50%] xl:bg-center xl:bg-cover',
        })}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={transition}
      />
      <Eligibility className="max-lg:my-4" ref={ref} usedCalculator={usedCalculator} />
    </div>
  </>
));

export default EligibilityWithBackground;
