import React, { forwardRef } from 'react';
import { type UseFormRegisterReturn } from 'react-hook-form';
import { checkboxClick } from '../../utils/analytics';
import { cn, joinValues } from '../../utils/helpers';
import Checkmark from '../../public/icons/checkmark.svg';
import Text from './Text';

const alignments = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
  justify: 'text-justify',
};

type Alignment = keyof typeof alignments;

type CheckboxProps = UseFormRegisterReturn & {
  description?: string;
  labelKey?: string;
  className?: string;
  alignment?: Alignment;
};

const Checkbox = forwardRef<HTMLLabelElement, CheckboxProps>(({
  description,
  labelKey,
  className,
  alignment = 'left',
  ...register
}, ref) => {
  const { name, disabled } = register;

  return (
    <label
      ref={ref}
      className={cn(className, 'cursor-pointer relative flex items-start gap-3')}
      htmlFor={name}
      role="presentation"
      onMouseDown={(event) => event.preventDefault()}
    >
      <div className="flex items-center">
        <input
          data-testid={name}
          id={name}
          type="checkbox"
          onClick={(event) => checkboxClick(name, event)}
          {...register}
          className={joinValues({
            base: 'absolute h-5 w-5 cursor-pointer opacity-0 peer disabled:cursor-auto',
          })}
        />
        <div
          className={joinValues({
            base: 'flex items-center justify-center w-5 h-5 border rounded border-gray-dark',
            checked: 'peer-checked:bg-primary peer-checked:border-none',
            focus: 'peer-focus:ring-primary peer-focus:ring-1 peer-focus:ring-offset-2',
            disabled: 'peer-disabled:bg-slate-50 peer-disabled:border-slate-200',
            animation: 'transition-colors duration-300',
          })}
        >
          <Checkmark
            className={joinValues({
              base: 'w-3 h-3 text-white opacity-0 pointer-events-none fill-white',
              animation: 'transition-opacity duration-300',
            })}
          />
        </div>
      </div>

      <Text
        className={cn(alignments[alignment], disabled && 'text-slate-500 cursor-auto')}
        size="sm"
        langKey={labelKey}
      />
    </label>
  );
});

export default Checkbox;
