import { featureFlags } from '../utils/constants';
import useSplit from './useSplit';

const useSplitBanner = () => {
  const { customBanner } = featureFlags;
  const { isReady, treatment, config } = useSplit(customBanner);

  return {
    isReady: isReady && treatment !== 'off',
    message: config.message,
  };
};

export default useSplitBanner;
