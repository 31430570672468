/* eslint-disable no-console */
import i18n from '../i18n';
import { storageKeys } from './constants.ts';
import { isBrowser } from './helpers.ts';

let isTester;
let isFirstVisit;

if (isBrowser) {
  isFirstVisit = !localStorage.getItem(storageKeys.hasVisited);
}

if (isFirstVisit && isBrowser) {
  localStorage.setItem(storageKeys.hasVisited, 'true');
}

const isDebug = process.env.NODE_ENV === 'development';

const updateAnalyticSettings = async (value) => {
  if (!value) {
    return;
  }

  console.log(await i18n.t('error.analytics.disabled'));
  isTester = value;
};

const measure = (detail) => {
  if (isDebug) {
    console.log('Analytics:', { ...detail });
  }

  if (isTester) {
    return;
  }

  const event = new CustomEvent('sendDataView', { detail });
  document.dispatchEvent(event);
};

const pageView = (url) => measure({
  page_name: url,
});

const tagComponent = (label, type, value) => {
  measure({
    event_action: `${type} click`,
    event_category: type,
    event_label: label,
    event_value: value,
  });
};

const tagSwitch = (label, value) => measure({
  event_action: `switch ${value ? 'checked' : 'unchecked'}`,
  event_category: 'switch',
  event_label: label,
});

const tagCheckbox = (label, value) => measure({
  event_action: `checkbox ${value ? 'checked' : 'unchecked'}`,
  event_category: 'checkbox',
  event_label: label,
});

const tagLink = (label, url) => measure({
  event_action: 'link click',
  event_category: 'link',
  event_label: `${label} - ${url}`,
});

const buttonClick = (event) => {
  const { textContent } = event.target ?? {};
  const eventName = textContent ?? event;

  if (!eventName) {
    return;
  }

  tagComponent(eventName, 'button', eventName);
};

const switchClick = (text, value) => {
  if (!text || !value) {
    return;
  }

  tagSwitch(text, value);
};

const checkboxClick = (text, event) => {
  const { checked } = event.target ?? {};

  if (!text) {
    return;
  }

  tagCheckbox(text, checked);
};

const linkClick = (event) => {
  const { textContent, href } = event.target ?? {};

  if (!textContent || !href) {
    return;
  }

  tagLink(textContent, href);
};

const dropdownSelect = (event) => {
  const text = event?.title;
  if (!text) {
    return;
  }

  tagComponent(text, 'dropdown', text);
};

const getStepName = ({ name, newToTheBank }) => {
  const newToTheBankString = newToTheBank ? ' - NTTB' : '';
  return `${name}${newToTheBankString}`;
};

const getSubstepName = ({ name, treatment }) => {
  const treatmentName = treatment ? `- ${treatment} -` : '-';
  const preFixVisitor = isFirstVisit ? 'new' : 'existing';
  return `${name} ${treatmentName} ${preFixVisitor} visitor`;
};

const getStepType = (name) => {
  if (name.includes('About you')) {
    return '1';
  }

  if (name.includes('Next steps')) {
    return '4';
  }

  return '0';
};

// TODO: measuring application attributes doesn't work at the moment.
// The team responsible for fixing this is working on it.
// Their progress can be tracked here:
// https://dev.azure.com/raboweb/DCP/_workitems/edit/8334138
// If a fix takes too long, we should consider removing the application attributes.
const measurePage = ({ step, name, treatment, newToTheBank }) => measure({
  application_stepname: getStepName({ name, newToTheBank }),
  application_stepnumber: step,
  application_process: 'stp',
  application_id: 'invoice_finance',
  application_segment: 'business',
  application_substepnumber: step,
  application_substepname: getSubstepName({ name, treatment }),
  application_steptype: getStepType(name),
  application_attribute_1: name,
});

const measureHomepage = (data) => {
  const name = 'Homepage';
  const step = '1';

  measurePage({ step, name, ...data });
};

const measureAboutYou = (data) => {
  const { value } = data;
  const calculator = value ? ' - Calculator' : '';
  const name = `About you${calculator}`;
  const step = '2';

  measurePage({ step, name, ...data });
};

const measureInvoicesAddAccount = (data) => {
  const name = 'Invoices add account';
  const step = '3';

  measurePage({ step, name, ...data });
};

const measureInvoicesOverview = (data) => {
  const name = 'Invoices overview';
  const step = '4';

  measurePage({ step, name, ...data });
};

const measureTransactionConsent = (data) => {
  const name = 'Transaction consent';
  const step = '5';

  measurePage({ step, name, ...data });
};

const measureTransactionsAddAccount = (data) => {
  const name = 'Transactions add account';
  const step = '6';

  measurePage({ step, name, ...data });
};

const measureRedirection = (data) => {
  const name = 'Redirection';
  const step = '7';

  measurePage({ step, name, ...data });
};

const measureTransactionSuccess = (data) => {
  const name = 'Transaction success';
  const step = '8';

  measurePage({ step, name, ...data });
};

const measureCollectTransactions = (data) => {
  const name = 'Collect transactions';
  const step = '9';

  measurePage({ step, name, ...data });
};

const measureTransactionsOverview = (data) => {
  const name = 'Transactions overview';
  const step = '10';

  measurePage({ step, name, ...data });
};

const measureGetOffers = (data) => {
  const name = 'Get offers';
  const step = '11';

  measurePage({ step, name, ...data });
};

const measurePersonalOffer = (data) => {
  const name = 'Personal offer';
  const step = '12';

  measurePage({ step, name, ...data });
};

const measureNextSteps = (data) => {
  const name = 'Next steps';
  const step = '13';

  measurePage({ step, name, ...data });
};

const measureFeedbackSuccess = (data) => {
  const name = 'Feedback success';
  const step = '14';

  measurePage({ step, name, ...data });
};

const measureNewToTheBank = (data) => {
  const name = 'New to the bank';
  const step = '15';

  measurePage({ step, name, ...data });
};

// The remaining functions are not part of the funnel.
// They are either sidebranches from the funnel (FAQ),
// or error pages (Request failed).
const measureCookies = (data) => {
  const name = 'Cookies';
  const step = '0';

  measurePage({ step, name, ...data });
};

const measureFaq = (data) => {
  const name = 'FAQ';
  const step = '0';

  measurePage({ step, name, ...data });
};

const measureContact = (data) => {
  const name = 'Contact';
  const step = '0';

  measurePage({ step, name, ...data });
};

const measureRecurringModal = (data) => {
  const name = 'Recurring modal';
  const step = '0';

  measurePage({ step, name, ...data });
};

const measureRequestFailed = (data) => {
  const { value } = data;
  const name = `Request failed - ${value ?? 'Unknown'}`;
  const step = '0';

  measurePage({ step, name, ...data });
};

// The functions below do not measure page views.
// They are used to measure specific events.
const measureVideo = (data) => {
  const { value } = data;
  const name = `Video - ${value ?? 'Unknown'}`;
  const step = '0';

  measurePage({ step, name, ...data });
};

const measureLinkClick = (data) => {
  const { value } = data;
  const name = `Link click - ${value ?? 'Unknown'}`;
  const step = '0';

  measurePage({ step, name, ...data });
};

const measureEligibilityModal = (data) => {
  const name = 'Eligibility modal';
  const step = '0';

  measurePage({ step, name, ...data });
};

export {
  updateAnalyticSettings,
  pageView,
  buttonClick,
  switchClick,
  checkboxClick,
  linkClick,
  dropdownSelect,
  measureHomepage,
  measureVideo,
  measureAboutYou,
  measureInvoicesAddAccount,
  measureInvoicesOverview,
  measureTransactionConsent,
  measureTransactionsAddAccount,
  measureRedirection,
  measureTransactionSuccess,
  measureCollectTransactions,
  measureTransactionsOverview,
  measureGetOffers,
  measurePersonalOffer,
  measureNextSteps,
  measureFeedbackSuccess,
  measureNewToTheBank,
  measureCookies,
  measureFaq,
  measureContact,
  measureRecurringModal,
  measureRequestFailed,
  measureLinkClick,
  measureEligibilityModal,
};
