import api from '../utils/api.ts';

// --------------------------------------------------
// ----------------- AUTHENTICATION -----------------
// --------------------------------------------------
export function getJwtToken({ partnerId }) {
  const path = 'dls-authentication/v3/authentication/invfin/anonymous-tokens';

  const headers = partnerId
    ? { originated: partnerId }
    : {};

  return api.post({ path, headers });
}

export function getRedirectToken({ hash, correlationId }) {
  const path = 'dls-authentication/v1/authentication/redirect/authorize';

  const body = {
    hash,
    correlationId,
  };

  return api.post({ path, body });
}
