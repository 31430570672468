import React from 'react';
import { useTranslation } from 'react-i18next';
import { isValidEmail } from '../../utils/validation.ts';
import Dropdown from '../ui/Dropdown.tsx';
import Input from '../ui/Input.tsx';
import TextArea from '../ui/TextArea.tsx';

export const inputNames = {
  firstName: 'first_name',
  email: 'email',
  surname: 'surname',
  productType: 'product_type',
  message: 'message',
};

function SendMessageForm({ register, control, errors, onSubmit, formId, isDisabled }) {
  const { t } = useTranslation();

  const {
    firstName,
    surname,
    email,
    productType,
    message,
  } = inputNames;

  const options = [
    { id: 'factuur_financiering', title: t('text_65aee1bd8c098047721f92e9') },
  ];

  return (
    <form
      onSubmit={onSubmit}
      id={formId}
      className="flex flex-col gap-4"
    >
      <div className="flex gap-2">
        <Input
          placeholder={t('text_65aee1bd8c098047721f92e2')}
          label={t('text_65aee1bd8c098047721f92e1')}
          disabled={isDisabled}
          className="w-full"
          error={errors[firstName]?.message}
          {...register(firstName, {
            required: t('error.required'),
          })}
        />
        <Input
          placeholder={t('text_65aee1bd8c098047721f92e3')}
          label={t('text_65aee1bd8c098047721f92e4')}
          disabled={isDisabled}
          className="w-full"
          error={errors[surname]?.message}
          {...register(surname, {
            required: t('error.required'),
          })}
        />
      </div>
      <Input
        type="email"
        placeholder={t('text_65aee1bd8c098047721f92e6')}
        label={t('text_65aee1bd8c098047721f92e5')}
        disabled={isDisabled}
        error={errors[email]?.message}
        {...register(email, {
          required: t('error.required'),
          validate: {
            [email]: (value) => isValidEmail(value) || t('error.email'),
          },
        })}
      />
      <Dropdown
        options={options}
        displayValue={(value) => value?.title}
        control={control}
        placeholder={t('text_65aee1bd8c098047721f92e8')}
        error={errors[productType]?.message}
        name={productType}
        label={t('text_65aee1bd8c098047721f92e7')}
        rules={{
          required: t('error.required'),
        }}
      />
      <TextArea
        label={t('text_65aee1bd8c098047721f92ea')}
        error={errors[message]?.message}
        placeholder={t('text_65aee1bd8c098047721f92eb')}
        maxLength={160}
        {...register(message, {
          required: t('error.required'),
        })}
      />
    </form>
  );
}

export default SendMessageForm;
