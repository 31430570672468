import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { LayoutGroup } from 'framer-motion';
import useAuth from '../../hooks/useAuth';
import useInView from '../../hooks/useInView.tsx';
import usePageBottom from '../../hooks/usePageBottom';
import usePageMeasure from '../../hooks/usePageMeasure.tsx';
import useRemoveToken from '../../hooks/useRemoveToken.tsx';
import EligibilityButton from '../../components/container/EligibilityButton';
import ExplanationVideo from '../../components/container/ExplanationVideo.tsx';
import Footer from '../../components/container/Footer.tsx';
import KiyohReviews from '../../components/container/KiyohReviews.tsx';
import ProcessSteps from '../../components/container/ProcessSteps';
import ProductsFaq from '../../components/container/ProductsFaq.tsx';
import SupportedBanks from '../../components/container/SupportedBanks';
import OtherProduct from '../../components/ui/OtherProduct.tsx';
import PageTitle from '../../components/ui/PageTitle.tsx';
import Quote from '../../components/ui/Quote.tsx';
import BlogUpdates from './BlogUpdates.tsx';
import Calculator from './Calculator.tsx';
import EligibilityWithBackground from './EligibilityWithBackground.tsx';
import ProductRequirements from './ProductRequirements.tsx';
import ProductUsp from './ProductUsp.tsx';

function Home() {
  const parentRef = useOutletContext();
  const { t } = useTranslation();

  usePageMeasure({ name: 'homepage' });
  const page = 'factuur_financiering';

  const isBottom = usePageBottom({ parentRef, offset: 50 });
  const [viewRef, isInView] = useInView();

  const isRemoved = useRemoveToken();
  useAuth(isRemoved);

  const [usedCalculator, setUsedCalculator] = useState(false);

  const otherProductItems = {
    header: 'text_65a4842dab1a0c007b58f4a6',
    text: 'text_65a4842dab1a0c007b58f4a7',
    button: 'text_65a4842dab1a0c007b58f4a8',
  };

  const moveToTop = () => {
    parentRef.current.scroll({ top: 0, behavior: 'smooth' });
  };

  const goToAllProducts = () => {
    window.open(t('link.all_products'), '_blank');
  };

  return (
    <div className="flex flex-col items-center">
      <LayoutGroup id={page}>
        <PageTitle title="text_65d140bb4ed26100a2801f6d" description="text_65d140bb4ed26100a2801f6f" />

        <EligibilityWithBackground
          page={page}
          ref={viewRef}
          usedCalculator={usedCalculator}
        />

        <SupportedBanks />
        <KiyohReviews
          location="homepage"
          className="pb-12"
        />
        <ExplanationVideo
          className="text-center"
          page="landingpage"
        />
        <Quote />
        <ProductRequirements />
        <BlogUpdates />
        <ProductUsp />
        <Calculator setUsedCalculator={setUsedCalculator} />
        <ProcessSteps />
        <OtherProduct
          content={otherProductItems}
          name="other_finances"
          onClick={goToAllProducts}
        />
        <ProductsFaq />
        <Footer />
        <EligibilityButton
          onClick={moveToTop}
          show={!isInView}
          isBottom={isBottom}
        />
      </LayoutGroup>
    </div>
  );
}

export default Home;
