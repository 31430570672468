import { useEffect, useState } from 'react';

function usePageBottom({ offset = 0, parentRef }) {
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    const container = parentRef?.current;

    const handleScroll = () => {
      const { scrollTop, offsetHeight, scrollHeight } = container;
      const position = scrollTop + offsetHeight + offset;

      setIsBottom(position >= scrollHeight);
    };

    container?.addEventListener('scroll', handleScroll);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [parentRef]);

  return isBottom;
}

export default usePageBottom;
