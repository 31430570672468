import React, { type ReactNode } from 'react';
import { motion } from 'framer-motion';
import { cn, joinValues, transition } from '../../utils/helpers';
import ProgressBar from '../container/ProgressBar';
import ComponentLayout, { type Variant } from './ComponentLayout';

function PageProgressBar({ noPadding = false }) {
  return (
    <ProgressBar className={cn('hidden lg:flex lg:mb-8', noPadding && 'lg:px-12')} />
  );
}

type MainColumnProps = {
  children: ReactNode;
  variant?: Variant;
  className?: string;
  noPadding?: boolean;
};

function MainColumn({
  children,
  variant,
  noPadding = false,
  className = '',
}: MainColumnProps) {
  return (
    <ComponentLayout
      transition={transition}
      layoutId="main_column"
      className="flex-1 lg:px-0 xl:px-0 lg:overflow-hidden"
      variant={variant}
    >
      <div className="flex flex-col w-full">
        <motion.div
          transition={transition}
          layoutId="column_container"
          className={joinValues({
            base: 'flex flex-col w-full',
            lg: 'lg:py-12 lg:bg-white lg:rounded',
            options: cn(className, !noPadding && 'lg:px-12'),
          })}
        >
          {children}
        </motion.div>
      </div>
    </ComponentLayout>
  );
}

type SideColumnProps = {
  children: ReactNode;
};

function SideColumn({ children }: SideColumnProps) {
  return (
    <motion.div
      layoutId="side_column"
      layout="position"
      transition={transition}
      className="flex flex-col w-full lg:max-w-column-info lg:gap-6"
    >
      {children}
    </motion.div>
  );
}

type PageLayoutProps = {
  children: ReactNode;
};

function PageLayout({ children }: PageLayoutProps) {
  return (
    <ComponentLayout
      transition={transition}
      layoutId="page_layout"
      variant="full"
      className={joinValues({
        base: 'flex-col pt-4 h-full',
        lg: 'lg:h-auto lg:flex-row lg:bg-gray-background lg:gap-12 lg:pb-12',
      })}
    >
      {children}
    </ComponentLayout>
  );
}

PageLayout.MainColumn = MainColumn;
PageLayout.SideColumn = SideColumn;

PageLayout.PageProgressBar = PageProgressBar;

export default PageLayout;
