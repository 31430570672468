import React from 'react';
import { pageMapper } from '../../utils/constants';
import { joinValues, transition } from '../../utils/helpers';
import ComponentLayout from '../layout/ComponentLayout';
import Divider from '../ui/Divider';
import Header from '../ui/Header';
import Link from '../ui/Link';
import Text from '../ui/Text';
import Accordion from './Accordion';

function ProductsFaq() {
  const component = 'products_faq';

  const items = [
    {
      id: 1,
      question: 'text_65a4842dab1a0c007b58f4dc',
      answer: 'faq.item53.answer',
    },
    {
      id: 2,
      question: 'text_65a4842dab1a0c007b58f4de',
      answer: 'faq.item37.answer',
    },
    {
      id: 3,
      question: 'text_65a4842dab1a0c007b58f4e0',
      answer: 'text_65a4842dab1a0c007b58f4e1',
    },
  ];

  return (
    <ComponentLayout
      transition={transition}
      layoutId={component}
      className="bg-blue-dark w-full justify-center"
    >
      <div
        className={joinValues({
          base: 'self-center flex flex-col min-w-full py-10 gap-10 items-center text-center',
          sm: 'sm:px-10 sm:min-w-0',
          md: 'md:max-w-xl md:px-0',
          lg: 'lg:max-w-component lg:py-20 lg:gap-16',
        })}
      >
        <Header
          langKey="text_65a4842dab1a0c007b58f4a9"
          element="h2"
          color="white"
        />
        <div className="flex flex-col gap-4">
          {items.map(({ question, answer, id }) => (
            <Accordion
              langKey={question}
              key={id}
              color="white"
              size="h4"
              chevronColor="white"
            >
              <div className="flex flex-col gap-4 mt-2 text-left">
                <Text
                  langKey={answer}
                  color="white"
                />
                <Divider color="white" />
              </div>
            </Accordion>
          ))}
          <Link className="mt-8" href={pageMapper.faq}>
            <Text
              langKey="text_65a4842dab1a0c007b58f4ad"
              color="white"
              className="hover:text-[inherit]"
            />
          </Link>
        </div>
      </div>
    </ComponentLayout>
  );
}

export default ProductsFaq;
