// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-container {
  --tooltipBackground: rgb(38 38 38 / 0.9) !important;
  --tooltipBorder: rgb(0 0 0 / 0) !important;

  padding: 1rem !important;
  border-radius: 0.25rem !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  min-width: 10rem;
  max-width: 20rem;
}

.tooltip-container[data-popper-placement*='top'] .tooltip-arrow::after {
  border-style: none;
}

.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow::after {
  border-style: none;
}

.tooltip-container[data-popper-placement*='left'] .tooltip-arrow::after {
  border-style: none;
}

.tooltip-container[data-popper-placement*='right'] .tooltip-arrow::after {
  border-style: none;
}

.tooltip-container[data-popper-placement*='top'] .tooltip-arrow::before {
  border-color: var(--tooltipBackground) transparent transparent transparent !important;
}

.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow::before {
  border-color: transparent transparent var(--tooltipBackground) transparent !important;
}

.tooltip-container[data-popper-placement*='left'] .tooltip-arrow::before {
  border-color: transparent transparent transparent var(--tooltipBackground) !important;
}

.tooltip-container[data-popper-placement*='right'] .tooltip-arrow::before {
  border-color: transparent var(--tooltipBackground) transparent transparent !important;
}`, "",{"version":3,"sources":["webpack://./styles/tooltip.css"],"names":[],"mappings":"AAAA;EACE,mDAAmD;EACnD,0CAA0C;;EAE1C,wBAAwB;EACxB,iCAAiC;EACjC,+BAA+B;EAC/B,gCAAgC;EAChC,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qFAAqF;AACvF;;AAEA;EACE,qFAAqF;AACvF;;AAEA;EACE,qFAAqF;AACvF;;AAEA;EACE,qFAAqF;AACvF","sourcesContent":[".tooltip-container {\n  --tooltipBackground: rgb(38 38 38 / 0.9) !important;\n  --tooltipBorder: rgb(0 0 0 / 0) !important;\n\n  padding: 1rem !important;\n  border-radius: 0.25rem !important;\n  border-left-width: 0 !important;\n  border-right-width: 0 !important;\n  min-width: 10rem;\n  max-width: 20rem;\n}\n\n.tooltip-container[data-popper-placement*='top'] .tooltip-arrow::after {\n  border-style: none;\n}\n\n.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow::after {\n  border-style: none;\n}\n\n.tooltip-container[data-popper-placement*='left'] .tooltip-arrow::after {\n  border-style: none;\n}\n\n.tooltip-container[data-popper-placement*='right'] .tooltip-arrow::after {\n  border-style: none;\n}\n\n.tooltip-container[data-popper-placement*='top'] .tooltip-arrow::before {\n  border-color: var(--tooltipBackground) transparent transparent transparent !important;\n}\n\n.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow::before {\n  border-color: transparent transparent var(--tooltipBackground) transparent !important;\n}\n\n.tooltip-container[data-popper-placement*='left'] .tooltip-arrow::before {\n  border-color: transparent transparent transparent var(--tooltipBackground) !important;\n}\n\n.tooltip-container[data-popper-placement*='right'] .tooltip-arrow::before {\n  border-color: transparent var(--tooltipBackground) transparent transparent !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
