import React from 'react';
import { useTranslation } from 'react-i18next';
import { clearSession } from '../../utils/helpers.ts';
import Button from '../ui/Button.tsx';
import Header from '../ui/Header.tsx';
import Text from '../ui/Text.tsx';

function SessionExpired({ setIsOpen }) {
  const { t } = useTranslation();
  const component = 'session_expired';

  const onClick = () => {
    setIsOpen(false);
    clearSession();
    window.location.replace('/');
  };

  return (
    <div className="flex flex-col items-center gap-4 p-4 text-center">
      <Header
        langKey="text_65d14312b881a70085b44586"
        size="h1"
        element="h2"
      />
      <Text
        langKey="text_65d14312b881a70085b44587"
        size="sm"
      />
      <Button
        label={t('text_65d14312b881a70085b44588')}
        name={`${component}_start_new`}
        className="mt-2 w-fit"
        color="primary"
        onClick={onClick}
      />
    </div>
  );
}

export default SessionExpired;
