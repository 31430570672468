import React, { forwardRef, useRef } from 'react';
import { cn } from '../../utils/helpers';

const colors = {
  base: 'border-gray-medium',
  natural: 'border-gray-natural',
  dark: 'border-gray-[#c4c4c4]',
  secondary: 'border-secondary',
  white: 'border-white',
};

type DividerProps = {
  color?: keyof typeof colors;
  className?: string;
};

const Divider = forwardRef<HTMLDivElement, DividerProps>(({
  className,
  color = 'base',
}, ref) => {
  const localRef = ref ?? useRef(null);

  const options = cn(
    'border-t border-solid',
    className,
    colors[color],
  );

  return (
    <div ref={localRef} className={options} />
  );
});

export default Divider;
