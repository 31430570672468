import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { transition } from '../../utils/helpers.ts';
import Checkbox from '../ui/Checkbox.tsx';
import Combobox from '../ui/Combobox.tsx';
import Tooltip from '../ui/Tooltip.tsx';
import InfoIcon from '../../public/icons/info.svg';

export const inputName = 'search_company';

const MotionCheckbox = motion(Checkbox);

function EligibilityForm({
  onSubmit,
  register,
  options,
  control,
  displayValue,
  formName,
  error,
  isLoading,
}) {
  const Icon = <InfoIcon className="w-6" />;
  const { t } = useTranslation();

  return (
    <form
      onSubmit={onSubmit}
      id={formName}
      className="flex flex-col gap-4"
    >
      <Combobox
        options={options}
        displayValue={displayValue}
        control={control}
        isLoading={isLoading}
        placeholder={t('text_65a4842dab1a0c007b58f47f')}
        error={error[inputName]?.message}
        name={inputName}
        validation={{
          maxLength: {
            value: 200,
            message: t('error.max_length'),
          },
          required: t('error.required'),
          validate: {
            [inputName]: (value) => typeof value === 'object' || t('error.object'),
          },
        }}
        endAdornment={(
          <Tooltip content={Icon}>
            {t('tooltip.company')}
          </Tooltip>
        )}
      />
      <MotionCheckbox
        labelKey="eligibility.consent"
        layoutId="checkbox"
        transition={transition}
        {...register('eligibility_consent', {
          required: true,
        })}
      />
    </form>
  );
}

export default EligibilityForm;
