import React from 'react';
import { motion } from 'framer-motion';
import { transition } from '../../utils/helpers.ts';
import CrossIcon from '../../public/icons/cross_bold.svg';

function CloseButton({ onClose, name }) {
  return (
    <div className="pt-6 w-full lg:absolute lg:pt-0">
      <motion.button
        className="absolute p-2 top-0 right-0"
        type="button"
        tabIndex="-1"
        name={`${name}_close`}
        aria-label={`${name}_close`}
        onClick={onClose}
        layout="position"
      >
        <CrossIcon />
      </motion.button>
    </div>
  );
}

function ModalLayout({ children }) {
  return (
    <motion.div
      transition={transition}
      layoutId="modal_layout"
      className="relative flex flex-col gap-4 lg:gap-10"
    >
      {children}
    </motion.div>
  );
}

ModalLayout.CloseButton = CloseButton;

export default ModalLayout;
