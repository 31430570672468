import React from 'react';
import { useTranslation } from 'react-i18next';
import { joinValues, transition } from '../../utils/helpers.ts';
import ComponentLayout from '../layout/ComponentLayout.tsx';
import Button from '../ui/Button.tsx';

function EligibilityButton({ show, isBottom, onClick }) {
  const { t } = useTranslation();
  const component = 'eligibility.button';

  return (
    <ComponentLayout
      transition={transition}
      layoutId={component}
      className={joinValues({
        options: show ? 'bottom-0' : '-bottom-full',
        animation: 'transition-all duration-500',
        base: 'sticky justify-center w-full py-6 bg-white z-10',
        sm: 'md:hidden',
        shadow: !isBottom && 'drop-shadow-rabobank',
      })}
    >
      <Button
        name={`${component}_start_request`}
        label={t(component)}
        onClick={onClick}
        color="primary"
        className="w-full"
      />
    </ComponentLayout>
  );
}

export default EligibilityButton;
