import React, { type FC, type SVGAttributes, useState } from 'react';
import { SwiperSlide } from 'swiper/react';
import { Swiper } from 'swiper/types';
import { cn, transition } from '../../utils/helpers';
import ComponentLayout from '../../components/layout/ComponentLayout';
import Carousel from '../../components/ui/Carousel';
import Header from '../../components/ui/Header';
import Text from '../../components/ui/Text';
import DevicesIcon from '../../public/icons/raboIcons/devices.svg';
import TimeIcon from '../../public/icons/raboIcons/time.svg';
import TrendingIcon from '../../public/icons/raboIcons/trending.svg';

type Item = {
  Icon: FC<SVGAttributes<SVGElement>>
  header: string;
  text: string;
};

type UspItemProps = {
  isActive?: boolean;
  item: Item;
};

function UspItem({ isActive, item }: UspItemProps) {
  const iconHeight = 'h-24';
  const { Icon, header, text } = item;

  return (
    <div className="flex flex-col items-center gap-4 pb-8 flex-1 lg:pb-0">

      {/* Mobile */}
      <div className={cn('flex items-center justify-center lg:hidden', iconHeight)}>
        <Icon className={cn('transition-all duration-500', isActive ? iconHeight : 'h-11')} />
      </div>
      <Header
        className="text-center lg:hidden"
        langKey={header}
        element="h3"
      />

      {/* Desktop */}
      <Icon className="hidden lg:block h-[4.5rem] mb-2" />
      <Header
        langKey={header}
        element="h3"
        size="h4"
        className="hidden text-center lg:block"
      />
      <Text
        className="text-center"
        langKey={text}
      />
    </div>
  );
}

function ProductUsp() {
  const [activeIndex, setActiveIndex] = useState(0);
  const component = 'product_usp';

  const items = [
    {
      id: 1,
      header: 'text_65a4842dab1a0c007b58f48c',
      text: 'text_65a4842dab1a0c007b58f48d',
      Icon: TimeIcon,
    },
    {
      id: 2,
      header: 'text_65a4842dab1a0c007b58f4ba',
      text: 'text_65a4842dab1a0c007b58f4bb',
      Icon: DevicesIcon,
    },
    {
      id: 3,
      header: 'text_65a4842dab1a0c007b58f4bc',
      text: 'text_65a4842dab1a0c007b58f4bd',
      Icon: TrendingIcon,
    },
  ];

  const onSlideChange = (swiper: Swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  return (
    <ComponentLayout
      transition={transition}
      layoutId={component}
      className="justify-center w-full bg-blue-light"
    >
      <div className="flex flex-col w-full max-w-md gap-10 py-10 text-center lg:hidden">
        <Header
          langKey="text_65a4842dab1a0c007b58f48b"
          element="h2"
        />
        <Carousel name={component} onSlideChange={onSlideChange}>
          {items.map((item, index) => (
            <SwiperSlide key={item.id}>
              <UspItem
                isActive={activeIndex === index}
                item={item}
              />
            </SwiperSlide>
          ))}
        </Carousel>
      </div>

      <div className="flex-col items-center hidden gap-16 py-20 lg:flex">
        <Header
          langKey="text_65a4842dab1a0c007b58f48b"
          element="h2"
          className="max-w-2xl text-center"
        />

        <div className="flex lg:gap-14 xl:gap-20">
          {items.map((item) => (
            <UspItem item={item} key={item.id} />
          ))}
        </div>
      </div>
    </ComponentLayout>
  );
}

export default ProductUsp;
