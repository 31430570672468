export default {
  balloonPayments: 0,
  eligible: true,
  leaseEligible: false,
  scoreIsRed: false,
  stgEligible: false,
  offersList: [
    {
      id: 'dc79c39f-5f3d-46f1-a11e-d7dbc7090a78',
      interestPercentage: 0.012,
      loanAmount: 9880,
      showOptions: true,
      totalInterestCost: 120,
      totalInvoiceAmount: 10000,
      invoicesBreakdown: [
        {
          debtorName: 'Debtor A',
          invoiceId: 'InvoiceId1',
          invoiceNumber: 'Invoice A',
          invoiceDate: '2024-10-1',
          interestCost: 120,
          interestRate: 0.012,
          invoiceAmount: 10000,
          invoiceDuration: 30,
          invoiceLoanTerm: 30,
          loanAmount: 9880,
          loanExtension: false,
        },
      ],
    },
  ],
};
