import React, { createContext, useMemo, useReducer } from 'react';
import { restrictedPageOrder } from './constants.ts';

const initialState = {
  selectedBank: '',
  isGlobalLoading: false,
  isTester: false,
  flowFinished: false,
  activeInvoiceId: undefined,
  prevLocation: undefined,
  maxLocationId: 0,
  transactionsTab: undefined,
  isRecurringCustomer: false,
  requester: {},
  invoices: [],
  transactions: {
    consent: false,
    connections: [],
  },
  offers: [],
  userUsedExampleCalculator: false,
};

const actionNames = {
  ADD_BANK_ACCOUNT: 'ADD_BANK_ACCOUNT',
  SET_INVOICE: 'SET_INVOICE',
  UPDATE_INVOICE: 'UPDATE_INVOICE',
  REMOVE_INVOICE: 'REMOVE_INVOICE',
  SET_ACTIVE_INVOICE_ID: 'SET_ACTIVE_INVOICE_ID',
  REMOVE_BANK_ACCOUNTS: 'REMOVE_BANK_ACCOUNTS',
  SET_CONSENT: 'SET_CONSENT',
  SET_REQUESTER: 'SET_REQUESTER',
  REMOVE_REQUESTER: 'REMOVE_REQUESTER',
  SET_OFFERS: 'SET_OFFERS',
  SET_IS_GLOBAL_LOADING: 'SET_IS_GLOBAL_LOADING',
  SET_IS_TESTER: 'SET_IS_TESTER',
  SET_LOCATION: 'SET_LOCATION',
  SET_SELECTED_BANK: 'SET_SELECTED_BANK',
  SET_RECURRING_CUSTOMER: 'SET_RECURRING_CUSTOMER',
  SET_MAX_LOCATION: 'SET_MAX_LOCATION',
  SET_TRANSACTIONS_TAB: 'SET_TRANSACTIONS_TAB',
  SET_FLOW_FINISHED: 'SET_FLOW_FINISHED',
  SET_USER_USED_EXAMPLE_CALCULATOR: 'SET_USER_USED_EXAMPLE_CALCULATOR',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionNames.SET_IS_GLOBAL_LOADING:
      return {
        ...state,
        isGlobalLoading: action.value,
      };
    case actionNames.SET_IS_TESTER:
      return {
        ...state,
        isTester: action.value,
      };
    case actionNames.SET_CONSENT: {
      const { transactions } = state;
      transactions.consent = action.value;

      return {
        ...state,
        transactions,
      };
    }
    case actionNames.SET_SELECTED_BANK: {
      const selectedBank = action.value;

      return {
        ...state,
        selectedBank,
      };
    }
    case actionNames.ADD_BANK_ACCOUNT: {
      const newBankName = action.value.bankName;
      const newAccounts = action.value.accounts;

      const { transactions } = state;
      const { connections } = transactions;

      const bank = connections
        .find(({ bankName }) => bankName === newBankName);

      if (bank) {
        newAccounts.forEach((account) => {
          const newIban = account.iban;
          const exists = bank.accounts.some(({ iban }) => iban === newIban);

          if (exists) {
            return;
          }

          bank.accounts.push(account);
        });
      } else {
        connections.push(action.value);
      }

      return {
        ...state,
        transactions,
      };
    }
    case actionNames.SET_INVOICE: {
      const { value } = action;
      const { invoices } = state;

      const index = invoices.findIndex((invoice) => invoice.id === value.id);

      if (invoices[index]) {
        invoices[index] = { ...invoices[index], ...value };
      } else {
        invoices.push(value);
      }

      return {
        ...state,
        invoices,
      };
    }
    case actionNames.REMOVE_INVOICE: {
      const { value } = action;
      const { invoices } = state;

      const newInvoices = invoices.filter((invoice) => invoice.id !== value);

      return {
        ...state,
        invoices: newInvoices,
      };
    }
    case actionNames.SET_ACTIVE_INVOICE_ID: {
      const activeInvoiceId = action.value;

      return {
        ...state,
        activeInvoiceId,
      };
    }
    case actionNames.SET_OFFERS: {
      const offers = action.value;

      return {
        ...state,
        offers,
      };
    }
    case actionNames.REMOVE_BANK_ACCOUNTS: {
      const { transactions } = state;
      transactions.connections = [];

      return {
        ...state,
        transactions,
      };
    }
    case actionNames.SET_REQUESTER: {
      const requester = { ...state.requester, ...action.value };

      return {
        ...state,
        requester,
      };
    }
    case actionNames.REMOVE_REQUESTER: {
      return {
        ...state,
        requester: {},
      };
    }
    case actionNames.SET_LOCATION: {
      const { curr, prev } = action.value;
      const { maxLocationId } = state;

      const prevLocation = prev;
      const id = Math.max(restrictedPageOrder[curr] ?? 0, maxLocationId);

      return {
        ...state,
        maxLocationId: id,
        prevLocation,
      };
    }
    case actionNames.SET_MAX_LOCATION: {
      const id = action.value;

      return {
        ...state,
        maxLocationId: id,
      };
    }
    case actionNames.SET_FLOW_FINISHED: {
      return {
        ...state,
        flowFinished: true,
      };
    }
    case actionNames.SET_RECURRING_CUSTOMER: {
      const { value } = action;
      const isRecurringCustomer = value;

      return {
        ...state,
        isRecurringCustomer,
      };
    }
    case actionNames.SET_TRANSACTIONS_TAB:
      return {
        ...state,
        transactionsTab: action.value,
      };
    case actionNames.SET_USER_USED_EXAMPLE_CALCULATOR:
      return {
        ...state,
        userUsedExampleCalculator: action.value,
      };
    default:
      return state;
  }
};

const StoreContext = createContext();

function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('state:', state);
  }

  // Everytime the CountProvider component re-renders a new reference
  // to object or array is being passed as value to
  // StateContext.Provider and hence even if the actual value may not
  // have changed, the Context consumers are re-rendered since the
  // reference check fails for the value
  //
  // https://stackoverflow.com/a/62231233
  const value = useMemo(() => {
    const actions = {
      setConsent: () => {
        dispatch({ type: actionNames.SET_CONSENT, value: true });
      },
      setInvoice: (invoice) => {
        dispatch({ type: actionNames.SET_INVOICE, value: invoice });
      },
      removeInvoice: (id) => {
        dispatch({ type: actionNames.REMOVE_INVOICE, value: id });
      },
      setActiveInvoiceId: (id) => {
        dispatch({ type: actionNames.SET_ACTIVE_INVOICE_ID, value: id });
      },
      setRequester: (requester) => {
        dispatch({ type: actionNames.SET_REQUESTER, value: requester });
      },
      removeRequester: () => {
        dispatch({ type: actionNames.REMOVE_REQUESTER });
      },
      setIsGlobalLoading: (isLoading) => {
        dispatch({ type: actionNames.SET_IS_GLOBAL_LOADING, value: isLoading });
      },
      addBankAccount: (account) => {
        dispatch({ type: actionNames.ADD_BANK_ACCOUNT, value: account });
      },
      setOffers: (offers) => {
        dispatch({ type: actionNames.SET_OFFERS, value: offers });
      },
      removeBankAccounts: () => {
        dispatch({ type: actionNames.REMOVE_BANK_ACCOUNTS });
      },
      setIsTester: (isTester) => {
        dispatch({ type: actionNames.SET_IS_TESTER, value: isTester });
      },
      setLocation: ({ curr, prev }) => {
        dispatch({ type: actionNames.SET_LOCATION, value: { curr, prev } });
      },
      setMaxLocation: (id) => {
        dispatch({ type: actionNames.SET_MAX_LOCATION, value: id });
      },
      setSelectedBank: (bankName) => {
        dispatch({ type: actionNames.SET_SELECTED_BANK, value: bankName });
      },
      setRecurringCustomer: (isRecurringCustomer) => {
        dispatch({ type: actionNames.SET_RECURRING_CUSTOMER, value: isRecurringCustomer });
      },
      setTransactionsTab: (tab) => {
        dispatch({ type: actionNames.SET_TRANSACTIONS_TAB, value: tab });
      },
      setFlowFinished: () => {
        dispatch({ type: actionNames.SET_FLOW_FINISHED });
      },
      setUserUsedExampleCalculator: () => {
        dispatch({ type: actionNames.SET_USER_USED_EXAMPLE_CALCULATOR });
      },
    };

    return { state, actions };
  }, [state]);

  return (
    <StoreContext.Provider value={value}>
      {children}
    </StoreContext.Provider>
  );
}

export { StoreProvider, StoreContext };
