import React from 'react';
import { cn } from '../../utils/helpers';

type StrokeColors =
  | 'primary'
  | 'secondary'
  | 'white'
  | 'black'
  | 'message'
  | 'error'
  | 'warning'
  | 'gray';

type FillColors =
  | 'primary'
  | 'secondary'
  | 'white'
  | 'black'
  | 'message'
  | 'error'
  | 'warning'
  | 'gray'
  | 'transparent';

const strokeMapper: { [key in StrokeColors]: string } = {
  primary: 'stroke-primary',
  secondary: 'stroke-secondary',
  white: 'stroke-white',
  black: 'stroke-off-black',
  message: 'stroke-secondary',
  error: 'stroke-error',
  warning: 'stroke-warning',
  gray: 'stroke-gray-dark',
};

const fillMapper: { [key in FillColors]: string } = {
  primary: 'fill-primary',
  secondary: 'fill-secondary',
  white: 'fill-white',
  black: 'fill-off-black',
  message: 'fill-secondary',
  error: 'fill-error',
  warning: 'fill-warning',
  gray: 'fill-gray-dark',
  transparent: 'fill-transparent',
};

type InfoIconProps = {
  className?: string;
  strokeColor?: StrokeColors;
  fillColor?: FillColors;
};

function InfoIcon({
  className,
  strokeColor = 'gray',
  fillColor = 'white',
}: InfoIconProps) {
  const stroke = strokeMapper[strokeColor];
  const fill = fillMapper[fillColor];

  return (
    <div className={cn(className, stroke, fill)}>
      <svg width="100%" height="100%" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.5 12C0.5 5.65214 5.65214 0.5 12 0.5C18.3479 0.5 23.5 5.65214 23.5 12C23.5 18.3479 18.3479 23.5 12 23.5C5.65214 23.5 0.5 18.3479 0.5 12Z" stroke="current" strokeLinecap="square" fill="current" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9ZM11 16C11 16.55 11.45 17 12 17C12.55 17 13 16.55 13 16V12C13 11.45 12.55 11 12 11C11.45 11 11 11.45 11 12V16Z" fill="#000099" strokeOpacity="0" />
      </svg>
    </div>
  );
}

export default InfoIcon;
