import React from 'react';
import { useTranslation } from 'react-i18next';
import { cn, joinValues } from '../../utils/helpers.ts';
import ComponentLayout from '../../components/layout/ComponentLayout.tsx';
import Button from '../../components/ui/Button.tsx';
import Header from '../../components/ui/Header.tsx';
import Text from '../../components/ui/Text.tsx';

function WordPartner() {
  const { t } = useTranslation();

  const component = 'contact.partner';
  const backgroundImage = 'bg-partner bg-no-repeat bg-cover';

  const goToPartners = () => {
    window.open(t('link.partners'), '_blank');
  };

  const goToWordPartner = () => {
    window.open(t('link.word_partner'), '_blank');
  };

  return (
    <>
      {/* Mobile background image */}
      <div className={cn(backgroundImage, 'min-h-[375px] bg-[28%_60%] md:hidden')} />

      <ComponentLayout className="flex-col items-center py-10 bg-gray-background lg:bg-transparent">
        <div
          className={joinValues({
            base: 'flex gap-6 w-full items-center justify-center',
            lg: 'lg:bg-gray-background lg:rounded lg:overflow-clip',
          })}
        >
          {/* Content */}
          <div className="flex flex-col gap-4 md:basis-1/2 lg:p-14 lg:gap-8">
            <div className="flex flex-col gap-2 max-w-md lg:gap-4 lg:max-w-sm">
              <Header
                langKey="text_65a4a5af8a80c500a343f56a"
                element="h3"
              />
              <Text
                langKey="text_65a4a5af8a80c500a343f56b"
              />
            </div>

            <div className="flex flex-col gap-4 sm:flex-row lg:gap-6 lg:mr-auto">
              <Button
                name={`${component}_get_contact`}
                label={t('text_65a4a5af8a80c500a343f56c')}
                className="w-full whitespace-nowrap"
                color="primary"
                onClick={goToWordPartner}
              />
              <Button
                name={`${component}_more_info`}
                label={t('text_65a4a5af8a80c500a343f56d')}
                className="w-full whitespace-nowrap"
                variant="outline"
                onClick={goToPartners}
              />
            </div>
          </div>

          {/* Desktop background image */}
          <div
            className={joinValues({
              base: 'rounded basis-1/2 h-full hidden bg-[28%_40%]',
              image: backgroundImage,
              md: 'md:block',
              lg: 'lg:bg-[40%_40%] lg:rounded-none',
              xl: 'xl:bg-[20%_40%]',
            })}
          />
        </div>
      </ComponentLayout>
    </>
  );
}

export default WordPartner;
