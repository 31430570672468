import { v4 as uuidv4 } from 'uuid';
import { toDateString } from '../../utils/helpers.ts';
import { requester2 } from '../../e2e/fixtures/requester';
import { createDate } from './bankConnections.ts';

export function createInvoiceScan({
  issueDate,
  period,
  invoiceId = '',
  amount = '',
  debtorName = '',
  debtorKvk = '',
  companyName = '',
  companyKvk = '',
}) {
  const invoiceDate = new Date(issueDate);

  const invoice = {
    requestId: invoiceId,
    invoiceId,
    amount,
    issueDate: '',
    deadlineDate: '',
    rawText: '',
    debtorName,
    debtorKvk,
    companyName,
    companyKvk,
  };

  if (issueDate) {
    invoice.issueDate = toDateString(invoiceDate);
  }

  if (period != null) {
    const deadlineDate = new Date(issueDate);
    deadlineDate.setDate(invoiceDate.getDate() + period);

    invoice.deadlineDate = toDateString(deadlineDate);
  }

  return invoice;
}

export function createInvoice(invoice) {
  const details = invoice ?? {
    amount: 10000,
    debtorKvk: requester2.kvkNumber,
    debtorName: requester2.name,
    debtorType: 'B2B',
    invoiceNumber: 'Invoice A',
    issueDate: createDate(),
    deadlineDate: createDate('near_future'),
  };

  const {
    amount,
    deadlineDate,
    debtorKvk,
    debtorName,
    debtorType,
    invoiceNumber,
    issueDate,
    invoiceId,
  } = details;

  const id = invoiceId ?? uuidv4();
  const interestRate = 0.012;

  const invoiceIssueDate = issueDate ?? createDate();
  const invoiceDeadlineDate = deadlineDate ?? createDate('near_future');

  const interestCost = amount * interestRate;
  const loanAmount = amount - interestCost;

  return {
    eligible: true,
    totalInvoiceAmount: amount,
    totalInterestCost: interestCost,
    totalLoanAmount: loanAmount,
    message: '[]',
    invoiceData: {
      invoiceNumber,
      invoiceId: id,
      invoiceAmount: amount,
      invoiceIssueDate,
      invoiceDeadlineDate,
      debtorKvk,
      debtorName,
      debtorType,
      preliminaryOffer: {
        duration: 30,
        interestCost,
        interestRate,
        invoiceAmount: amount,
        loanAmount,
      },
    },
  };
}
