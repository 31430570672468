import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutGroup, motion } from 'framer-motion';
import usePageMeasure from '../../hooks/usePageMeasure.tsx';
import { transition } from '../../utils/helpers.ts';
import ContactCards from '../../components/container/ContactCards';
import Footer from '../../components/container/Footer.tsx';
import ComponentLayout from '../../components/layout/ComponentLayout.tsx';
import Header from '../../components/ui/Header.tsx';
import PageTitle from '../../components/ui/PageTitle.tsx';
import Text from '../../components/ui/Text.tsx';
import FaqItems from './FaqItems';
import FaqPicker from './FaqPicker';

function Faq() {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState();

  usePageMeasure({ name: 'faq' });
  const page = 'faq';

  const options = [
    {
      id: 1,
      title: t('text_65a491524d70350064949312'),
      items: [
        'item50',
        'item51',
        'item84',
        'item85',
        'item86',
        'item90',
        'item87',
        'item88',
      ],
    },
    {
      id: 2,
      title: t('text_65a491524d70350064949319'),
      items: [
        'item53',
        'item91',
        'item92',
        'item93',
        'item37',
        'item94',
        'item95',
        'item96',
        'item97',
        'item98',
        'item99',
        'item57',
      ],
    },
    {
      id: 3,
      title: t('text_65a491524d70350064949322'),
      items: [
        'item100',
        'item101',
        'item102',
        'item103',
        'item104',
        'item105',
        'item106',
        'item107',
        'item63',
        'item64',
        'item65',
        'item67',
        'item108',
        'item59',
        'item109',
        'item42',
        'item60',
        'item61',
      ],
    },
    {
      id: 4,
      title: t('text_66a10e9ccec3f600957f4380'),
      items: [
        'item110',
        'item111',
        'item102',
        'item42',
        'item112',
      ],
    },
    {
      id: 5,
      title: t('text_65a491524d70350064949333'),
      items: [
        'item113',
        'item114',
        'item115',
        'item68',
        'item66',
        'item116',
        'item117',
        'item118',
      ],
    },
    {
      id: 6,
      title: t('text_66a1100ce860f1005e886385'),
      items: [
        'item119',
        'item120',
        'item121',
        'item122',
        'item123',
        'item124',
        'item125',
        'item126',
      ],
    },
    {
      id: 7,
      title: t('text_66a1131d124ba800e7d6cbbe'),
      items: [
        'item127',
        'item128',
        'item129',
        'item130',
        'item131',
        'item132',
        'item133',
        'item134',
        'item135',
      ],
    },

  ];

  return (
    <LayoutGroup id={page}>
      <PageTitle title="text_65d140bb4ed26100a2801f72" description="text_65d140bb4ed26100a2801f74" />

      <ComponentLayout
        className="flex-col py-10 gap-10 w-full lg:gap-20 lg:py-20 lg:items-center"
      >
        <motion.div
          className="flex flex-col w-full gap-10 lg:gap-20 xl:px-10 2xl:px-28"
          layout="position"
        >
          <div className="flex flex-col w-full gap-8 lg:items-center lg:gap-10">
            <Header
              className="lg:text-5xl"
              langKey="text_65a491524d703500649492fe"
              size="h2"
              element="h1"
            />

            <FaqPicker
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              options={options}
            />
          </div>

          <FaqItems
            selectedOption={selectedOption}
            options={options}
          />
        </motion.div>
      </ComponentLayout>

      <ComponentLayout
        transition={transition}
        layoutId="contact"
        className="flex-col bg-gray-background py-10 gap-10 lg:items-center lg:py-20"
      >
        <div className="flex flex-col gap-2 text-center">
          <Header
            langKey="text_65a491524d70350064949306"
            element="h3"
          />
          <Text
            langKey="text_65a491524d70350064949307"
          />
        </div>

        <ContactCards />
      </ComponentLayout>

      <motion.div
        transition={transition}
        layoutId="footer"
      >
        <Footer />
      </motion.div>
    </LayoutGroup>
  );
}

export default Faq;
