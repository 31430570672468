import { useContext, useEffect, useState } from 'react';
import { storageKeys } from '../utils/constants';
import { StoreContext } from '../utils/store';

function useRemoveToken() {
  const { state, actions } = useContext(StoreContext);

  const { requester } = state;
  const { removeRequester } = actions;

  const [tokenRemoved, setTokenRemoved] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem(storageKeys.jwt);
    const { kvkNumber } = requester;

    if (!token || !kvkNumber) {
      return;
    }

    sessionStorage.removeItem(storageKeys.jwt);
    removeRequester();

    const shouldRemoveToken = Boolean(token && kvkNumber);
    setTokenRemoved(shouldRemoveToken);
  }, []);

  return tokenRemoved;
}

export default useRemoveToken;
