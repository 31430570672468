import React, { type RefObject } from 'react';
import { cn } from '../../utils/helpers';
import CircleText, { type CircleTextColor } from '../ui/CircleText';
import Header from '../ui/Header';
import LoadingCircle from '../ui/LoadingCircle';
import Text from '../ui/Text';
import CheckmarkIcon from '../../public/icons/checkmark.svg';

type TranslationText = {
  key: string;
  value?: Record<string, string>;
};

export type Step = {
  header: string;
  id: number;
  text?: TranslationText;
  ref?: RefObject<HTMLDivElement>;
};

type ProgressStepsProps = {
  steps: Step[];
  activeId: number;
  showLoader?: boolean;
};

function ProgressSteps({ steps, activeId, showLoader = false }: ProgressStepsProps) {
  return (
    <div className="flex flex-col pt-4">
      {steps.map((step, index) => {
        const isLastItem = index === steps.length - 1;

        const { id, header, text } = step;
        const hasText = Boolean(text);

        const isActive = id === activeId;
        const isPreviousId = activeId - id === 1;

        const isFinished = id < activeId;
        const isDisabled = id > activeId;

        const borderColor = isFinished
          ? 'border-secondary'
          : 'border-gray-dark';

        let color: CircleTextColor = 'white';

        if (isFinished) {
          color = 'blue';
        }

        if (isActive) {
          color = 'secondary';
        }

        const topBorderPadding = showLoader && isPreviousId && 'pb-[5px]';
        const botBorderPadding = showLoader && isActive && 'pt-[5px]';

        return (
          <div
            className={cn('flex flex-col', isDisabled && 'opacity-75')}
            key={id}
          >
            <div className="flex gap-4">
              <LoadingCircle>
                <CircleText
                  disabled={isDisabled}
                  color={color}
                >
                  {isFinished
                    ? <CheckmarkIcon className="w-2.5" />
                    : id}
                </CircleText>

                {isActive && showLoader && <LoadingCircle.Loader />}
              </LoadingCircle>

              <Header
                langKey={header}
                element="h2"
                size="h5"
                className="flex-1 pt-0.5 -mb-0.5"
                color={isDisabled ? 'gray' : 'secondary'}
              />
            </div>

            <div className="flex gap-4 py-2">
              <div className={cn('flex justify-center basis-6 min-h-[16px]', topBorderPadding, botBorderPadding)}>
                {!isLastItem && (
                  <div className={cn('w-px h-full border-l', borderColor)} />
                )}
              </div>
              {hasText && (
                <Text
                  color={isDisabled ? 'gray' : 'black'}
                  size="sm"
                  langKey={text?.key}
                  className="flex-1"
                  values={text?.value}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ProgressSteps;
