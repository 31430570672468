export function isKeyOf<T extends Record<string, unknown>>(obj: T, key: unknown): key is keyof T {
  return typeof key === 'string' && key in obj;
}

export function isValueIn<T>(obj: Record<string, T>, value: unknown): value is T {
  return Object.values(obj).some((item) => item === value);
}

export function isBlob(value: unknown): value is Blob {
  return value instanceof Blob;
}
