import React from 'react';
import { useTranslation } from 'react-i18next';
import { buttonClick } from '../../utils/analytics';
import { joinValues } from '../../utils/helpers.ts';
import Header from '../ui/Header.tsx';
import Text from '../ui/Text.tsx';

function ButtonCard({ onClick, header, text, Icon, className, name }) {
  const { t } = useTranslation();
  const component = 'button_card';

  const handleOnClick = () => {
    onClick();

    const event = t(`event.${component}.click`, { component: name });
    buttonClick(event);
  };

  return (
    <button
      className={joinValues({
        base: 'flex flex-col p-6 gap-4 bg-white text-left transition-shadow duration-300',
        hover: 'hover:shadow-rabobank-md',
        animation: 'transition-shadow duration-300',
        border: 'border rounded border-gray-medium',
        options: className,
      })}
      type="button"
      aria-label={name}
      id={name}
      name={name}
      onClick={handleOnClick}
    >
      <Icon />
      <div className="flex flex-col">
        <Header langKey={header} element="h4" />
        <Text langKey={text} />
      </div>
    </button>
  );
}

export default ButtonCard;
