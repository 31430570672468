import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../hooks/useIsMobile';
import Modal from '../ui/Modal.tsx';
import MailIcon from '../../public/icons/raboIcons/mail.svg';
import PhoneIcon from '../../public/icons/raboIcons/person.svg';
import ButtonCard from './ButtonCard';
import SendMessage from './SendMessage';

function ContactCards() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const [requestMailIsOpen, setRequestMailIsOpen] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-10 w-full lg:flex-row">
        <ButtonCard
          Icon={PhoneIcon}
          header="text_65a4a393b6720800c5c5592e"
          text="text_65a4a393b6720800c5c55930"
          className="flex-1"
          name="create_call"
          onClick={() => {
            window.location.href = t('text_65d143184fd1b3009489cc1e');
          }}
        />
        <ButtonCard
          Icon={MailIcon}
          header="text_65a4a393b6720800c5c55932"
          text="text_65a4a393b6720800c5c55934"
          className="flex-1"
          name="send_message"
          onClick={() => setRequestMailIsOpen(true)}
        />
      </div>

      <Modal
        isOpen={requestMailIsOpen}
        noFocus={isMobile}
        setIsOpen={setRequestMailIsOpen}
        position="bottom"
        className="max-w-2xl"
      >
        <SendMessage setIsOpen={setRequestMailIsOpen} />
      </Modal>
    </>
  );
}

export default ContactCards;
