// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-pagination-bullet {

    position: relative;

    margin: 0px;

    display: inline-flex;

    height: 1rem;

    width: 1rem;

    border-radius: 9999px;

    border-width: 1px;

    --tw-border-opacity: 1;

    border-color: rgb(0 0 153 / var(--tw-border-opacity));

    background-color: transparent;

    padding: 0.125rem
}

.carousel-pagination-bullet-active .inner {

    height: 100%;

    width: 100%;

    border-radius: 9999px;

    --tw-bg-opacity: 1;

    background-color: rgb(0 0 153 / var(--tw-bg-opacity))
}

.swiper {

    margin-top: -1rem;

    padding-top: 1rem
}

.show .swiper {

    overflow: visible
}

.swiper-slide {

    height: auto
}

.swiper-pagination {

    position: relative;

    margin-left: auto;

    margin-right: auto;

    display: flex;

    max-width: -moz-fit-content;

    max-width: fit-content;

    gap: 0.5rem;

    padding-top: 1rem
}

@media (min-width: 1024px) {

    .swiper-pagination {

        padding-top: 2rem
    }
}

.swiper-horizontal > .swiper-pagination-bullets {

    bottom: 0px
}

.centered .swiper-wrapper {

    justify-content: center
}`, "",{"version":3,"sources":["webpack://./styles/carousel.css"],"names":[],"mappings":"AACE;;IAAA,kBAAiG;;IAAjG,WAAiG;;IAAjG,oBAAiG;;IAAjG,YAAiG;;IAAjG,WAAiG;;IAAjG,qBAAiG;;IAAjG,iBAAiG;;IAAjG,sBAAiG;;IAAjG,qDAAiG;;IAAjG,6BAAiG;;IAAjG;AAAiG;;AAIjG;;IAAA,YAA8C;;IAA9C,WAA8C;;IAA9C,qBAA8C;;IAA9C,kBAA8C;;IAA9C;AAA8C;;AAI9C;;IAAA,iBAAiB;;IAAjB;AAAiB;;AAIjB;;IAAA;AAAuB;;AAIvB;;IAAA;AAAa;;AAIb;;IAAA,kBAAyD;;IAAzD,iBAAyD;;IAAzD,kBAAyD;;IAAzD,aAAyD;;IAAzD,2BAAyD;;IAAzD,sBAAyD;;IAAzD,WAAyD;;IAAzD;AAAyD;;AAAzD;;IAAA;;QAAA;IAAyD;AAAA;;AAIzD;;IAAA;AAAe;;AAIf;;IAAA;AAAqB","sourcesContent":[".carousel-pagination-bullet {\n  @apply relative m-0 p-0.5 w-4 h-4 bg-transparent rounded-full inline-flex border border-secondary;\n}\n\n.carousel-pagination-bullet-active .inner {\n  @apply w-full h-full rounded-full bg-secondary;\n}\n\n.swiper {\n  @apply -mt-4 pt-4;\n}\n\n.show .swiper {\n  @apply overflow-visible;\n}\n\n.swiper-slide {\n  @apply h-auto;\n}\n\n.swiper-pagination {\n  @apply relative flex gap-2 pt-4 mx-auto max-w-fit lg:pt-8;\n}\n\n.swiper-horizontal > .swiper-pagination-bullets {\n  @apply bottom-0;\n}\n\n.centered .swiper-wrapper {\n  @apply justify-center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
