import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../ui/Button.tsx';
import Header from '../ui/Header.tsx';
import Text from '../ui/Text.tsx';
import DeskWithPizza from '../../public/icons/desk_with_pizza.svg';

function InvalidCompany({ setIsOpen }) {
  const { t } = useTranslation();
  const component = 'eligibility.company.fail';

  const onHomeClick = () => {
    setIsOpen(false);
    window.location.replace('/');
  };

  const onKvkClick = () => {
    setIsOpen(false);
    window.open(t('text_65d86d673f0cfe0089dc952a'), '_blank', 'noreferrer');
  };

  return (
    <div className="flex flex-col items-center gap-6 text-center w-full">
      <DeskWithPizza className="w-full" />
      <Header
        langKey="text_65d86d673f0cfe0089dc9526"
        element="h4"
      />
      <Text
        langKey="text_65d86d673f0cfe0089dc9527"
        size="sm"
      />
      <Button
        name={`${component}_go_to_kvk`}
        label={t('text_65d86d673f0cfe0089dc9528')}
        onClick={onKvkClick}
        className="w-full"
        color="primary"
      />
      <Button
        name={`${component}_back_to_home`}
        label={t('text_65d86d673f0cfe0089dc9529')}
        onClick={onHomeClick}
        className="w-full"
        variant="outline"
      />
    </div>
  );
}

export default InvalidCompany;
