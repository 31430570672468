import React from 'react';
import { measureLinkClick } from '../../utils/analytics';
import { cn } from '../../utils/helpers';
import ComponentLayout from '../layout/ComponentLayout';
import Kiyoh from '../../public/images/kiyoh_reviews.jpg';

const imageProperties = {
  homepage: {
    classname: 'max-w-96',
  },
  kortlopendeLeningLandingPage: {
    classname: 'max-w-96 xl:max-w-40 xl:absolute xl:top-[58%] xl:right-0',
  },
} as const;

type KiyohReviewsProperties = {
  location: keyof typeof imageProperties;
  className?: string;
};

function KiyohReviews({ location, className }: KiyohReviewsProperties) {
  const reviewUrl = 'https://www.kiyoh.com/reviews/1076980/rabobank_factuur_financiering';

  const handleClick = () => {
    window.open(reviewUrl, '_blank');
    measureLinkClick({ value: 'Kiyoh' });
  };

  return (
    <ComponentLayout className={cn('w-full justify-center', className)}>
      <button
        onClick={handleClick}
        type="button"
        name="kiyoh_reviews"
        aria-label="kiyoh_reviews"
      >
        <img
          className={imageProperties[location]?.classname}
          src={String(Kiyoh)}
          alt="Kiyoh Reviews"
        />
      </button>
    </ComponentLayout>
  );
}
export default KiyohReviews;
