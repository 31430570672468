import React from 'react';
import { cn } from '../../utils/helpers';
import Text from '../ui/Text';
import AttentionIcon from '../../public/icons/attention.svg';

type BannerProps = {
  label: string;
  className?: string;
};

function Banner({ label, className }: BannerProps) {
  return (
    <div className={cn(className, 'flex items-start justify-center w-full p-3 gap-2 bg-error')}>
      <AttentionIcon className="shrink-0" />
      <Text
        weight="semibold"
        size="sm"
        color="white"
      >
        {label}
      </Text>
    </div>
  );
}

export default Banner;
