import React, { useCallback } from 'react';
import i18next from 'i18next';
import { joinValues, transition } from '../../utils/helpers';
import ComponentLayout from '../layout/ComponentLayout';
import Header from './Header';

function Quote() {
  const component = 'quote';

  const image = 'bg-center bg-cover bg-quote bg-no-repeat bg-blend-multiply';
  const companyKey = `${component}.user2.company`;

  const Content = useCallback(() => (
    <>
      <div className="flex flex-col gap-2">
        <Header
          langKey="text_65a4842dab1a0c007b58f483"
          element="h5"
          color="white"
        />
        {i18next.exists(companyKey) && (
          <Header
            langKey={companyKey}
            element="h5"
            color="white"
          />
        )}
      </div>
      <Header
        langKey="text_65a4842dab1a0c007b58f484"
        element="h2"
        color="white"
      />
    </>
  ), []);

  return (
    <ComponentLayout
      transition={transition}
      layoutId={component}
      className={joinValues({
        background: image,
        base: 'w-full justify-center',
        lg: 'lg:bg-none max-lg:bg-[#cccccc]',
      })}
    >
      {/* Mobile */}
      <div
        className={joinValues({
          base: 'flex flex-col gap-6 max-w-component',
          text: 'text-center',
          spacing: 'pt-10 pb-36',
          lg: 'lg:hidden',
        })}
      >
        <Content />
      </div>

      {/* Desktop */}
      <div
        className={joinValues({
          background: `${image} bg-[#cccccc]`,
          base: 'hidden w-full justify-center',
          spacing: 'pt-10 pb-36',
          lg: 'lg:flex lg:pt-32 lg:pb-40 lg:rounded',
        })}
      >
        <div
          className={joinValues({
            base: 'flex flex-col gap-6 max-w-4xl',
            text: 'text-center',
          })}
        >
          <Content />
        </div>
      </div>
    </ComponentLayout>
  );
}

export default Quote;
