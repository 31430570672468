import React, { type ChangeEvent, forwardRef, type KeyboardEvent, useState } from 'react';
import { type UseFormRegisterReturn } from 'react-hook-form';
import { motion } from 'framer-motion';
import { cn, joinValues, transition } from '../../utils/helpers';
import ErrorMessage from './ErrorMessage';

type TextAreaProps = UseFormRegisterReturn & {
  label?: string;
  showError?: boolean;
  error?: string;
  name: string;
  rows?: number;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
  disabled?: boolean;
  maxLength?: number;
  placeholder: string;
};

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({
  label,
  error,
  placeholder,
  rows = 3,
  ...register
}, ref) => {
  const { name, maxLength, onChange } = register;

  const [count, setCount] = useState(0);
  const hasError = Boolean(error);

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (maxLength) {
      setCount(event?.target?.value?.length ?? 0);
    }

    onChange(event);
  };

  return (
    <motion.div
      transition={transition}
      layoutId={`${name}_layout`}
      layout="position"
      className="flex flex-col"
    >
      <label htmlFor={name} className="block pl-px text-sm font-light text-off-black">
        {label}
      </label>

      <div
        className={joinValues({
          base: 'relative',
          variant: cn('rounded shadow-sm border', hasError ? 'border-error' : 'border-gray-300'),
        })}
      >
        <textarea
          data-testid={name}
          id={name}
          maxLength={maxLength}
          rows={rows}
          placeholder={placeholder}
          data-hj-masked
          {...register}
          ref={ref}
          onChange={handleOnChange}
          className={joinValues({
            base: 'block w-full peer text-sm font-light px-4 py-3 rounded resize-none scroll-auto',
            disabled: 'disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none',
            placeholder: 'placeholder:text-sm placeholder:font-light',
            focus: 'focus:outline-secondary focus:ring-0 focus:ring-offset-0',
            border: 'shadow-sm rounded',
            error: cn(
              hasError && 'pr-10',
              hasError ? 'focus:ring-error focus:border-error' : 'focus:border-secondary',
            ),
            padding: maxLength ? 'pb-8' : undefined,
          })}
          onKeyDown={(event: KeyboardEvent<HTMLTextAreaElement>) => {
            switch (event.code) {
              case 'Space': {
                const { value } = (event.target as HTMLTextAreaElement);
                const endsWithSpace = value?.endsWith(' ');

                if (endsWithSpace) {
                  event.preventDefault();
                }

                break;
              }
              case 'Enter':
              case 'NumpadEnter':
                event.preventDefault();
                break;
              default: break;
            }
          }}
        />
        {Boolean(maxLength) && (
          <div className="absolute right-2 bottom-1 text-right text-xs text-gray-dark">
            {`${count}/${maxLength}`}
          </div>
        )}
      </div>

      <ErrorMessage name={name} error={error} />
    </motion.div>
  );
});

export default TextArea;
